import {RouteNames} from "../../router/names";

const menu = {
    data: [
        {id: 1, label: 'Геополитика', value: 'geo', link: RouteNames.GEOPOLITICS},
        {id: 2, label: 'Политика', value: 'politics', link: RouteNames.POLITICS },
        {id: 3, label: 'Экономика', value: 'economy', link: RouteNames.ECONOMY},
        {id: 4, label: 'Логистика', value: 'logistics', link: RouteNames.LOGISTICS},
        {id: 5, label: 'Мнения', value: 'opinions', link: RouteNames.OPINIONS}
    ]
}
export default menu;