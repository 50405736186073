import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchNews} from "../api/news";
import {GetNewsDataType} from "../api/news/types/news_type";
import {fetchListsApi, fetchSearchApi} from "../api/search";

interface IUsersState {
    data: [],
    dataLists: []
}

const initialState: IUsersState = {
    data: [],
    dataLists: []
};
export const fetchSearch = createAsyncThunk(
    'fetchSearch',
    async (payload: any) => {

        const response: any = await fetchSearchApi(payload);

        let result: any = [];
        let small = response.data.posts.filter((e: any)=>{
            return e.isBigPreview === 0;
        })

        let smallCounter=0;
        while(true){
            let triple = {small1:null, small2:null};

            triple.small1 = small[smallCounter];
            smallCounter++;

            triple.small2 = small[smallCounter];
            smallCounter++;

            result.push(triple)
            if(smallCounter > small.length)
                break;
        }

        result.total = response.data.total
        result.len = small.length
        result.data = response.data.posts
        return result
    }
)
export const fetchLists = createAsyncThunk(
    'fetchLists',
    async () => {
        const response: any = await fetchListsApi();
        return response.data.data
    }
)

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        clearDataSearch: (state, action) => {
            state.data = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSearch.fulfilled, (state, action) => {
            state.data = action.payload
        });
        builder.addCase(fetchLists.fulfilled, (state, action) => {
            state.dataLists = action.payload
        })
    },
});
export default searchSlice.reducer;
export const { clearDataSearch } = searchSlice.actions;