import React, {FC} from 'react';
import {Avatar, Col, Divider, Flex} from "antd";
import './style.scss'
import {EyeFilled} from "@ant-design/icons/lib";
import {Props} from "../../type";
import BoxTimeType from "./BoxTimeType";
import {NavLink} from "react-router-dom";
import {RouteNames} from "../../router/names";
import {useStoreDispatch} from "../../redux";
import {setClickViews} from "../../redux/newsData";

const NewsLeft: FC<Props> = (props: Props) => {
    const dispatch = useStoreDispatch()

    const handleViews = (id: number) =>{
         dispatch(setClickViews({postID: id}))
    }

    return (
        <>
            {
                props.data.map((item: any, i: number) =>
                    <div className={props.data.length - 1 === i ? "card-no-border" : "card"} key={i}>
                        <Flex vertical className={"box-img-text"}>
                            <NavLink to={{
                                pathname: `/detail/${item.id}`
                            }} state={[item, RouteNames.MAIN, {data: props.data}]} rel="noopener noreferrer"
                              onClick={() =>handleViews(item.id)}>
                                <Flex className={"box-text"}>
                                    <div className={"text"}>
                                        {item.text}
                                    </div>
                                    <div>
                                        {item.imgPreview !== null &&
                                          <img src={item.imgPreview} alt={''} className={"news-img"}/>
                                        }
                                    </div>
                                </Flex>
                            </NavLink>
                            <BoxTimeType data={item}/>
                            <Flex className={"box-user-avatar-views"}>
                                <div className={"box-user-avatar"}>
                                    {
                                        item.user.avatar === null ?
                                            <Avatar size={20}/> :
                                            <div className={"time-type"}>
                                                <Avatar src={item.user.avatar} alt={"avatar"} size={20}/>
                                            </div>

                                    }
                                    <div className={"text-user"}>{item.user.name}</div>
                                </div>
                                <div className={"box-views"}>
                                    <EyeFilled className={"views-img"}/>
                                    <div className={"time-type"}>{item.views}</div>
                                </div>
                            </Flex>
                        </Flex>
                    </div>
                )
            }
        </>
    );
};

export default NewsLeft;