import React, {FC, useEffect} from 'react';
import Layouts from "../../layout";
import {Col, Flex} from "antd";
import NewsLeft from "../../components/news/NewsLeft";
import './style.scss'
import NewsCenter from "../../components/news/NewsCenter";
import NewsRight from "../../components/news/NewsRight";
import quotes from '../../assets/image/double_quotes.png';
import img_divider from '../../assets/image/img_divider.png';
import {useIsXSmall} from "../../hooks/useIsXSmall";
import BlockSocial from "../../components/block_social";
import TgBannerMini from "../../components/tg_banner/mini";
import {RootState, useStoreDispatch} from "../../redux";
import {newsD} from "../../redux/newsData";
import {useSelector} from 'react-redux';
import LeftSkeleton from "../../components/skeleton/LeftSkeleton"
import CenterSkeleton from "../../components/skeleton/CenterSkeleton";
import RightSkeleton from "../../components/skeleton/RightSkeleton";
import TextWithLineBreaks from "../../utils/TextWithLineBreaks";

const Main: FC = () => {
    const isXSmall = useIsXSmall();
    const dispatch = useStoreDispatch()

    const dataNews: any = useSelector((state: RootState) => state.newsData.data);

    useEffect(() => {
        dispatch(newsD())

    }, []);


    return (
        <Layouts>
            <TgBannerMini/>
            <Flex>
                {dataNews !== null ?
                <>
                    <Col xs={0} sm={0} md={0} lg={6} xl={6}>
                        <div className={"col-left"}>
                            {dataNews.length !== 0 ?
                                <NewsLeft data={dataNews.dataLeft}/>
                                :
                                <LeftSkeleton col={10}/>
                            }
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className={isXSmall ? "col-center" : "col-center-mobile"}>
                            <div className={isXSmall ? "video-title" : "video-title-mobile"}>
                                {dataNews?.title_center?.label}</div>
                            {dataNews.length !== 0 ?
                                <NewsCenter data={dataNews?.dataCenter}/>
                                :
                                <CenterSkeleton col={10}/>
                            }
                        </div>
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={6} xl={6}>
                        <div className={"col-right"}>
                            <div className={"card-podcast"}>
                                {dataNews.length !== 0 ?
                                    <>
                                        <div className={"card-right"}>
                                            <Flex style={{justifyContent: 'space-between'}}>
                                                <Flex vertical className={"author-text"}>
                                                    <div>{dataNews?.quote?.author}</div>
                                                    <div>{dataNews?.quote?.year} г.</div>
                                                </Flex>
                                                <Flex style={{position: 'relative', top: 9}}>
                                                    <img src={quotes} alt={''}/>
                                                </Flex>
                                            </Flex>

                                            <div className={"quote_text"}>
                                                <TextWithLineBreaks text={dataNews?.quote?.text} />
                                            </div>
                                            <div className={"quote_divider"}>
                                                <img src={img_divider} alt={''} className={"quote-divider-img"}/>
                                            </div>
                                        </div>
                                        <div className={"card-podcast-title"}>
                                            <div>Подкаст «Без границ»</div>
                                        </div>
                                        <NewsRight data={dataNews.dataRight}/>
                                    </>
                                    :
                                    <RightSkeleton col={10}/>
                                }
                            </div>

                        </div>
                    </Col>
                </>
                    :
                    <RightSkeleton col={3}/>
                }
            </Flex>
            <div className={"divider-gradient"} style={{marginTop: 42}}/>
            <Flex style={{height: 85}}><Col/></Flex>
            {/*          <Flex vertical style={{height: '18vh'}}>
               <Col>
                    <div className={"h1"}>Нейросвета</div>
                </Col>
                <Col>
                    <Flex justify={"space-between"}>
                        <div className={"create-neuro"}>
                            <img src={img_neuro} alt={"img_neuro"}/>
                            <div>Создано нейросетью</div>
                        </div>
                        <Flex gap={10}>
                            <div>
                                <button
                                    type="button"
                                    disabled={!canScrollLeft}
                                    onClick={() => scrollContainerBy(-400)}
                                    className={cn("button-n", "buttonLeft", {
                                        "button-n--hidden": !canScrollLeft
                                    })}
                                >
                                    {'<'}
                                </button>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    disabled={!canScrollRight}
                                    onClick={() => scrollContainerBy(400)}
                                    className={cn("button-n", "buttonRight", {
                                        "button-n--hidden": !canScrollRight
                                    })}
                                >
                                    {'>'}
                                </button>
                            </div>
                        </Flex>
                    </Flex>

                </Col>
            </Flex>
            <Flex style={{width: '100%', height: '31.5vh'}}>
                {dataNews.length !== 0 &&
                  <CardNeuro data={dataNews.neuro} containerRef={containerRef}/>
                }
            </Flex>
            <Flex style={{height: '10vh'}}><Col/></Flex>*!/
            <Flex vertical={!isXSmall} style={{background: '#C6C6C6', width: '100%'}}>
                <Flex>
                    <img src={img_neuro_big} alt={"img_neuro_big"} className={"img_neuro_big"}/>
                </Flex>
                <Flex className={isXSmall ? "box-create-img-big" : "box-create-img-big-mobile"} vertical>
                    <div className={(isXSmall && IsTable) ? "h1" : "h1-mobile"}>Картина дня от Нейросветы</div>
                    <div className={(isXSmall && IsTable) ? "sub" : "sub-mobile"}>Создайте свою карикатуру по
                        фотографии
                    </div>
                    <Flex vertical className={(isXSmall && IsTable) ? "box-btn" : "box-btn-mobile"}>
                        <div className={isXSmall ? "create-img-big" : "create-img-big-mobile"}>
                            <img src={btn_icon_neuro}/>
                            <div>Создать свою карикатуру</div>
                        </div>
                        <div className={isXSmall ? "one-day" : "one-day-mobile"}>Примерное время ожидания — до 1 дня</div>
                    </Flex>
                </Flex>
            </Flex>
            <Flex style={{height: '30vh'}} justify={"center"} align={"center"}>
                <div className={"divider-gradient"}/>
            </Flex>*/}
            <BlockSocial/>
        </Layouts>
    );
};

export default Main;