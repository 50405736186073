import React from 'react';
import {Flex} from "antd";
import {useIsTable, useIsXSmall} from "../../hooks/useIsXSmall";
import tg_blue from "../../assets/image/tg_blue.png";
import youtube_red from "../../assets/image/youtube_red.png"
import podcast_blue from "../../assets/image/podcast_blue.png"
import {ArrowUpOutlined} from "@ant-design/icons"
import "./style.scss"

const BlockSocial = () => {
    const isXSmall = useIsXSmall();
    const isTable = useIsTable();
    return (
        <Flex className={"container-social"} vertical justify={"center"} align={"center"} style={{marginBottom: 80}}>
            <Flex style={isXSmall ? {height: '18vh',width: '85%'} : {height: '18vh'}  } justify={"center"} align={"center"} vertical>
                <div className={"h1"} style={{bottom: 24,left: 0}}>Оставайтесь с нами</div>
                <div className={"subst"}>
                    Следим за главными  новостями, обсуждаем горячие темы,  создаем комьюнити
                </div>
            </Flex>

            <Flex vertical={!isXSmall || !isTable} className={isXSmall ? "box-social" : "box-social-mobile"} >
                <Flex vertical className={"module-tg"} justify={"center"} align={"center"} data-text="img5">
                    <div style={{textAlign: "center",position: "absolute"}}>
                        <img src={tg_blue} alt={"tg_blue"}/>
                        <div className={"text-tg"}>Телеграм-канал</div>
                    </div>
                    <figcaption>
                        <ArrowUpOutlined className={"arrow"}/>
                        <div style={{display: 'flex', gap: 12}}>
                            <span>события</span>
                            <span>/</span>
                            <span>события</span>
                            <span>/</span>
                            <span>комментарии</span>
                        </div>
                    </figcaption>
                </Flex>
                <Flex vertical className={"module-tg"} justify={"center"} align={"center"} >
                    <div style={{textAlign: "center",position: "absolute"}}>
                       <img src={youtube_red} alt={"youtube_red"}/>
                       <div>Ютуб</div>
                    </div>
                    <figcaption>
                        <ArrowUpOutlined className={"arrow"}/>
                        <div style={{display: 'flex', gap: 12}}>
                            <span>интервью</span>
                            <span>/</span>
                            <span>подкасты</span>
                            <span>/</span>
                            <span>новости</span>
                        </div>
                    </figcaption>
                </Flex>
                <Flex vertical className={"module-tg"} justify={"center"} align={"center"} >
                    <div style={{textAlign: "center",position: "absolute"}}>
                       <img src={podcast_blue} alt={"podcast_blue"}/>
                       <div>Подкасты</div>
                    </div>
                    <figcaption>
                        <ArrowUpOutlined className={"arrow"}/>
                        <div style={{display: 'flex', gap: 12}}>
                            <span>Яндекс Музыка</span>
                            <span>/</span>
                            <span>VK</span>
                            <span>/</span>
                            <span>Apple</span>
                            <span>/</span>
                            <span>Spotify</span>
                        </div>
                    </figcaption>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default BlockSocial;