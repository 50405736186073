import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {GetResponseLogisticsData} from "../api/logistics/types/logistics_type";
import {fetchLogistics} from "../api/logistics";

interface ILogisticsState {
    data: Array<GetResponseLogisticsData>,
    total: number
}

const initialState: ILogisticsState = {
    data: [],
    total: 0
};
export const logisticsD = createAsyncThunk(
    'logisticsD',
    async (payload: number) => {
        let result: any | number = [];
        const response: any = await fetchLogistics(payload);

        if (response.status === 200) {
            result.data = response.data.data.posts
            result.total = response.data.data.total
            return result
        }else {
            return []
        }
    }
)

const logisticsSlice = createSlice({
    name: 'logistics',
    initialState,
    reducers: {
        setDataLogisticsReducers: (state, action) => {
            state.data = action.payload
        },
        setTotalReducers: (state, action) => {
            state.total = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logisticsD.fulfilled, (state, action) => {
            state.data = action.payload
        })
    },
});
export default logisticsSlice.reducer;
export const { setDataLogisticsReducers,setTotalReducers } = logisticsSlice.actions;