import React, {FC} from 'react';
import {Flex} from "antd";
import {Props} from "../../type";
import moment from 'moment'

const BoxTimeType: FC<Props> = (props: Props) => {
    return (
        <Flex className={"box-time-type"}>
            <div className={"time-type"}>{moment(props.data.dateTime).format('hh.mm')}</div>
            <div className={"time-type"}>&bull;</div>
            <div className={"time-type"}>{props.data.type.name}</div>
        </Flex>
    );
};

export default BoxTimeType;