import React, {useEffect, useState} from 'react';
import Layouts from "../../layout";
import './style.scss'
import {useStoreDispatch} from "../../redux";
import {politicsD} from "../../redux/politicsData";
import Content from "../../components/content";

const Politics = () => {
    const dispatch = useStoreDispatch();
    const [fetching, setFetching] = useState(true);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [dataPolitics, setDataPolitics]: any[] = useState<any[]>([]);
    const [total, setTotal] = useState(0);


    useEffect(() => {
        if (fetching){
            setLoading(true)
            dispatch(politicsD(currentPage)).then(async response =>{
                if (response.payload.data.length !== 0) {
                    await setDataPolitics([...dataPolitics, ...response.payload.data])
                    await setTotal(response.payload.total)
                    await setCurrentPage(prevState => prevState + response.payload.data.length)
                }
                setLoading(false)
            }).finally(() => setFetching(false))
        }
        document.addEventListener('scroll',scrollHandler);
        return function () {
            document.removeEventListener("scroll", scrollHandler)
        }
    }, [fetching]);

    const scrollHandler = (e: any) => {
        if (e.target.documentElement.scrollHeight - ((e.target.documentElement.scrollTop) + window.innerHeight) - 1000 < 100 &&
            dataPolitics.length < total) {
                setFetching(true)
        }
    }
    return (
        <Layouts>
           <Content data={dataPolitics} loading={loading} label={"Политика"}/>
        </Layouts>
    );
};

export default Politics;