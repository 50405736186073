import React from 'react';
import {Skeleton} from "antd";

const CenterSkeleton = (props: any) => {
    return (
        <>
            {
                Array.from({length: props.col},(v,i) =>
                    <Skeleton active key={i}/>
                )
            }
        </>
    );
};

export default CenterSkeleton;