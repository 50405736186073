import React, {useState} from 'react';
import DatePicker from 'react-datepicker'
import { getMonth, getYear  } from 'date-fns'
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import './style.scss'
import arrow from '../../assets/image/arrow.png'
import {Flex} from "antd";

const DatePickers = (props: any) => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [isOpen, setIsOpen] = useState(props.visible);
    const [startDate, endDate] = dateRange;
    const range = (start: number, end: number) => {
        return new Array(end - start).fill(1).map((d, i) => i + start);
    };
    const years = range(1990, getYear(new Date()) +10);

    const months = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
    ];

    const handleDate = (date: any) =>{
        if (date[0] !== null && date[1] !== null){
            setIsOpen(false)
            props.handleDate({start: date[0],end: date[1]})
        }
        setDateRange(date);
    }

    return (
        <DatePicker
            showIcon
            icon={arrow}
            locale={ru}
            selectsRange
            closeOnScroll
            startDate={startDate}
            endDate={endDate}
            open={isOpen}
            className={"input-date"}
            dateFormat="dd.MM.yyyy"
            renderCustomHeader={({
                                     date,
                                     changeYear,
                                     changeMonth,
                                     decreaseMonth,
                                     increaseMonth,
                                     prevMonthButtonDisabled,
                                     nextMonthButtonDisabled,
                                     decreaseYear,
                                     increaseYear,
                                     prevYearButtonDisabled,
                                     nextYearButtonDisabled
                                 }) => (
                <div style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                    }}>
                    <Flex vertical justify={"flex-start"} style={{width: '100%'}}>
                        <Flex>
                            <button onClick={decreaseYear} disabled={prevYearButtonDisabled}
                                   className={"button-date"}>
                                {"<"}
                            </button>
                            <select
                                className={"select-date"}
                                value={getYear(date)}
                                onChange={({ target: { value } }) => changeYear(Number(value))}
                            >
                                {years.map((option: any) => (
                                    <option key={option} value={option} className={"text14"} style={{fontWeight: "bold"}}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <button onClick={increaseYear} disabled={nextYearButtonDisabled}
                                    className={"button-date"}>
                                {">"}
                            </button>
                        </Flex>
                        <Flex>
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                    className={"button-date"}>
                                {"<"}
                            </button>
                            <select
                                className={"select-date"}
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                }
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                    className={"button-date"}>
                                {">"}
                            </button>
                        </Flex>
                    </Flex>
                </div>
            )}
            selected={startDate}
            onChange={(date: any) => {
                handleDate(date)
            }}
        />
    );
};

export default DatePickers;