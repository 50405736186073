import React, {FC} from 'react';
import menu from "./menu"
import "./style.scss"
import {Props} from "../../type";
import {NavLink} from "react-router-dom";
import {useIsXSmall} from "../../hooks/useIsXSmall";

const Menu: FC<Props> = (props: Props) => {
   const isXSmall = useIsXSmall();

    return (
        <div className={props.data==='header'? "menu" : 'menu-footer'}>
            <ul className={isXSmall ? "footer-ul" : "footer-ul-mobile"}>
                {
                    menu.data.map((item) =>
                        <li key={item.id} className={isXSmall ? "footer-li" : "footer-li-mobile"}>
                            <NavLink to={{pathname: `${item.link}`}}>
                                {item.label}
                            </NavLink>
                        </li>
                    )
                }
            </ul>
        </div>
    );
};

export default Menu;