import React from 'react';
import {privateRoutes} from "../router";
import {Routes, Route} from 'react-router-dom';

const AppRouter = () => {
    return (
        <Routes>
            {privateRoutes.map(route =>
                <Route path={route.path}
                       element={<route.element/>}
                       key={route.path}
                />
            )}
        </Routes>
    );
};

export default AppRouter;