import React, {useEffect, useRef, useState} from 'react';
import {Col, Flex, Form, Modal} from "antd";
import {NavLink, useLocation} from "react-router-dom";
import {RouteNames} from "../../../router/names";
import back_left from "../../../assets/image/back_left.png";
import logo from "../../../assets/image/logo.png";

const ConfirmDialog = (props: any) => {
    const CODE_LENGTH = new Array(3).fill(0);
    const [smsNum, setSmsNum] = useState('');
    const [timer, setTimer] = useState(5);
    const [showTimer, setShowTimer] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [again, setAgain] = useState(false)
    let params = useLocation()

    const id = useRef<any>(null);
    const clear = () => {
        window.clearInterval(id.current);
    };

    useEffect(() => {
        if (timer === 0) {
            clear();
            setDisabledBtn(false)
            setShowTimer(false)
            setAgain(true)
            setTimer(5)
        }
    }, [timer]);

    const handleConfirm = () => {
        setShowTimer(true)
        setDisabledBtn(true)
        setAgain(false)
        id.current = window.setInterval(() => {
            setTimer((time) => time - 1);
        }, 1000);
        return () => clear();
    }

    const onFinish = () => {

    };

    return (
        <>
            {!props.isXSmall ?
                <>
                    <div className={"paper"}/>
                    <div className={"login-logo"}>
                        <Flex style={{marginBottom: 57}}>
                            <Col span={3}>
                                <NavLink to={RouteNames.REGISTRATION} style={{marginLeft: 20}}>
                                    <img src={back_left} alt={"back_left"}/>
                                </NavLink>
                            </Col>
                            <Col style={{width: "100%"}}>
                                <img src={logo} style={{width: 148}}
                                     className={params?.state?.isXSmall ? 'logo' : "logo-mobile"} alt={"logo"}/>
                            </Col>
                            <Col span={3}/>
                        </Flex>
                        <Flex vertical gap={8} style={{marginBottom: 40, alignItems: "center"}}>
                            <div className={"text22 col"}>Подтвердите вход</div>
                            <div className={"text14 col"}>На номер +7 *** *** 88 77 было
                                отправлено SMS с кодом
                            </div>
                        </Flex>
                        <Flex className="wrap" gap={11} style={{marginBottom: 30, display: "inline-flex"}}>
                            <div>
                                {CODE_LENGTH.map((v, index) =>
                                    <input value={smsNum || ""} className={"input-sms"} key={index}
                                           defaultValue={""} type={"text"}
                                           onChange={() => setSmsNum("")} style={{background: '#DFDFDF'}}/>
                                )}
                            </div>
                            <div>
                                {CODE_LENGTH.map((v, index) =>
                                    <input value={smsNum || ""} className={"input-sms"} key={index}
                                           defaultValue={""} type={"text"}
                                           onChange={() => setSmsNum("")} style={{background: '#DFDFDF'}}/>
                                )}
                            </div>
                        </Flex>
                        <Flex justify={"center"}>
                            <button className={'button text16'}
                                    style={params?.state?.isXSmall ? {marginBottom: 12, width: 152} : {
                                        marginBottom: 30,
                                        width: '80%'
                                    }}
                                    disabled={disabledBtn} onClick={() => handleConfirm()}>Подтвердить
                            </button>
                        </Flex>
                        {showTimer &&
                        <div className={"text14"}
                             style={{color: '#959697', cursor: "pointer", textAlign: "center"}}>Запросить
                            код
                            повторно можно через 0:{timer}</div>
                        }
                        {again &&
                        <div className={"text14"}
                             style={{color: '#2083AE', cursor: "pointer", textAlign: "center"}}>
                            Отправить код повторно
                        </div>
                        }
                    </div>
                </>
                :
                <Modal
                    width={600}
                    open={props.isModalOpenConfirm}
                    centered
                    onCancel={props.handleCancelConfirm}
                    okButtonProps={{style: {display: 'none'}}}
                    cancelButtonProps={{style: {display: 'none'}}}>
                    <Form onFinish={props.registration} initialValues={{numSms: smsNum}}>
                        <Flex justify={"center"} vertical align={"center"}>
                            <div className={"text32"} style={{marginBottom: 20}}>Подтвердите вход</div>
                            <div className={params?.state?.isXSmall ? "text16" : "text14"}
                                 style={{marginBottom: 40, textAlign: "center"}}>На номер +7 *** *** 88 77 было
                                отправлено SMS с кодом
                            </div>
                            <Flex className="wrap" gap={11} style={{marginBottom: 30}}>
                                <Form onFinish={onFinish}>
                                    <Flex>
                                    <Form.Item>
                                        {CODE_LENGTH.map((v, index) =>
                                            <input value={smsNum || ""} className={"input-sms"} key={index}
                                                   defaultValue={""}
                                                   onChange={() => setSmsNum("")}/>
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        {CODE_LENGTH.map((v, index) =>
                                            <input value={smsNum || ""} className={"input-sms"} key={index}
                                                   defaultValue={""}
                                                   onChange={() => setSmsNum("")}/>
                                        )}
                                    </Form.Item>
                                    </Flex>
                                </Form>
                            </Flex>
                            <Flex>
                                <button className={'button text16'}
                                        style={params?.state?.isXSmall ? {marginBottom: 12, width: 152} : {
                                            marginBottom: 12,
                                            width: '100%'
                                        }}
                                        disabled={disabledBtn} onClick={() => handleConfirm()}>Подтвердить
                                </button>
                            </Flex>
                            {showTimer &&
                            <div className={"text16"} style={{color: '#959697', cursor: "pointer"}}>Запросить
                                код
                                повторно можно через 0:{timer}</div>
                            }
                            {again &&
                            <div className={"text16"} style={{color: '#2083AE'}}>Отправить код повторно</div>
                            }
                        </Flex>
                    </Form>
                </Modal>
            }
        </>
    )
};

export default ConfirmDialog;