import React from 'react';
import {Skeleton} from "antd";

const RightSkeleton = (props: any) => {
    return (
        <>
            {
                Array.from({length: props.col},(v,i) =>
                    <Skeleton active key={i} style={{marginLeft: 25}}/>
                )
            }
        </>
    );
};

export default RightSkeleton;