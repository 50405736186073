import React from 'react';
import {Flex} from "antd";
import "./style.scss"
import Menu from "../menu";
import {StarFilled} from "@ant-design/icons/lib";
import info from "./info";
import tg from '../../assets/image/tg.png'
import youtube from '../../assets/image/youtube.png'
import yandex from '../../assets/image/yandex.png'
import vk from '../../assets/image/vk.png'
import ios from '../../assets/image/ios.png'
import spotify from '../../assets/image/spotify.png'
import {useIsXSmall} from "../../hooks/useIsXSmall";

const Footer = () => {
    const isXSmall = useIsXSmall();
    return (
        <footer>
            <Flex className={"container-footer"} vertical>
                <Flex align="flex-end" justify="space-between" className={"box-footer"}>
                    <div>
                        <Menu data={"footer"}/>
                    </div>
                    {isXSmall &&
                    <div className={"box-pull-up"}>
                        <StarFilled className={"star-footer"}/>
                        <div className={"pull-up-footer"}>Поддерджать проект</div>
                    </div>
                    }
                </Flex>
                <div className={isXSmall ? "divider-footer" : "divider-footer-mobile"}/>
                {!isXSmall &&
                <>
                    <div className={"box-pull-up"} style={{marginTop: 40}}>
                        <StarFilled className={"star-footer"}/>
                        <div className={"pull-up-footer"}>Поддерджать проект</div>
                    </div>
                    <div className={"icon-social"} style={{marginTop: 56}}>
                        <img src={tg} alt={'tg'} className={"img-social"}/>
                        <img src={youtube} alt={'youtube'} className={"img-social"}/>
                        <img src={yandex} alt={'yandex'} className={"img-social"}/>
                        <img src={vk} alt={'vk'} className={"img-social"}/>
                        <img src={ios} alt={'ios'} className={"img-social"}/>
                        <img src={spotify} alt={'spotify'} className={"img-social"}/>
                    </div>
                </>
                }
                <Flex align="flex-end" justify="space-between" className={isXSmall ? "box-info" : "box-info-mobile"}
                      vertical={!isXSmall}>
                    <div style={{width: '100%'}}>
                        <ul>
                            {
                                info.data.map((item) =>
                                    <li key={item.id}>{item.label}</li>
                                )
                            }
                        </ul>
                        <ul>
                            {
                                info.data1.map((item) =>
                                    <li key={item.id}>{item.label}</li>
                                )
                            }
                        </ul>
                    </div>
                    {isXSmall &&
                    <div className={"icon-social"}>
                        <img src={tg} alt={'tg'} className={"img-social"}/>
                        <img src={youtube} alt={'youtube'} className={"img-social"}/>
                        <img src={yandex} alt={'yandex'} className={"img-social"}/>
                        <img src={vk} alt={'vk'} className={"img-social"}/>
                        <img src={ios} alt={'ios'} className={"img-social"}/>
                        <img src={spotify} alt={'spotify'} className={"img-social"}/>
                    </div>
                    }
                </Flex>

                  <Flex style={{marginTop: 98}} vertical gap={12}>
                      <div className={"text16"} style={{color: '#707070'}}>© 2023 «РУССКИЙ МИР»</div>
                      <Flex justify={'space-between'} vertical={!isXSmall}>
                          <div className={"text16"}
                               style={isXSmall ? {color: '#707070',width: 521} : {color: '#707070',width: '100%'}}>
                              Сетевое издание РИА Новости зарегистрировано в Федеральной службе по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор) 08 апреля 2014 года.
                          </div>
                          <div className={"age"} style={{marginBottom: 40}}>
                              <div className={"age-text"}>18+</div>
                          </div>
                      </Flex>
                  </Flex>
            </Flex>
        </footer>
    );
};

export default Footer;