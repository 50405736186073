import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {GetResponseOpinionsData} from "../api/opinions/types/opinions_type";
import {fetchOpinions} from "../api/opinions";

interface IOpinionsState {
    data: Array<GetResponseOpinionsData>,
    total: number
}

const initialState: IOpinionsState = {
    data: [],
    total: 0
};
export const opinionsD = createAsyncThunk(
    'opinionsD',
    async (payload: number) => {
        let result: any | number = [];
        const response: any = await fetchOpinions(payload);

        if (response.status === 200) {
            result.data = response.data.data.posts
            result.total = response.data.data.total
            return result
        }else {
            return []
        }
    }
)

const opinionsSlice = createSlice({
    name: 'opinions',
    initialState,
    reducers: {
        setDataOpinionsReducers: (state, action) => {
            state.data = action.payload
        },
        setTotalReducers: (state, action) => {
            state.total = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(opinionsD.fulfilled, (state, action) => {
            state.data = action.payload
        })
    },
});
export default opinionsSlice.reducer;
export const { setDataOpinionsReducers,setTotalReducers } = opinionsSlice.actions;