import React, {useEffect, useState} from 'react';
import Layouts from "../../layout";
import "./style.scss"
import {useIsXSmall} from "../../hooks/useIsXSmall";
import {format} from 'date-fns'
import {RootState, useStoreDispatch} from "../../redux";
import {fetchLists, fetchSearch,clearDataSearch} from "../../redux/searchData";
import Search from "../../components/search";
import {useSelector} from "react-redux";
import {Col, Divider, Row} from "antd";
import ResultSearchRight from "../../components/search/ResultSearchRight";
import ResultSearchLeft from "../../components/search/ResultSearchLeft";

const date = ['Все время', 'За неделю', 'За месяц', 'За год', "Выбрать период"];

const SearchPage = () => {
    const isXSmall = useIsXSmall();
    const dispatch = useStoreDispatch();
    const [selectedDate] = useState<string[]>([]);
    const [visible, setVisible] = useState(false);
    const [visibleResult, setVisibleResult] = useState(false);
    const [isDate, setIsDate] = useState<{ start: null | string, end: null | string }>({start: null, end: null});
    const [visibleFilter, setVisibleFilter] = useState(true);
    const [choiceSection, setChoiceSections] = useState("");
    const [choiceMaterials, setChoiceMaterials] = useState("");
    const [search, setSearch] = useState("");

    const searchData: any = useSelector((state: RootState) => state.searchData.data);
    const dataLists: any = useSelector((state: RootState) => state.searchData.dataLists);

    const filteredDate = date.filter((o) => !selectedDate.includes(o));


    useEffect(() => {
        dispatch(fetchLists())
        dispatch(clearDataSearch([]))
    }, []);

    const handleChange = (value: string | string[]) => {
        if (value === 'Выбрать период') {
            setVisible(true)
        }
        if (value === 'Все время') {
            setIsDate({start: 'Все время', end: null})
        }
        if (value === 'За неделю') {
            setIsDate({
                start: format(new Date().setDate(new Date().getDate() - 7), 'yyyy-MM-dd'),
                end: format(new Date(), 'yyyy-MM-dd')
            })
        }
        if (value === 'За месяц') {
            setIsDate({
                start: format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM-dd'),
                end: format(new Date(), 'yyyy-MM-dd')
            })
        }
        if (value === 'За год') {
            setIsDate({
                start: format(new Date().setFullYear(new Date().getFullYear() - 1), 'yyyy-MM-dd'),
                end: format(new Date(), 'yyyy-MM-dd')
            })
        }
    };
    const handleDate = (date: any) => {
        setIsDate({start: format(date.start, 'yyyy-MM-dd'), end: format(date.end, 'yyyy-MM-dd')})
        setVisible(false)
    };
    const handleVisible = () => {
        !visibleFilter ? setVisibleFilter(true) : setVisibleFilter(false)
    }
    const handleSearch = () => {
        dispatch(fetchSearch({
            search: search, dataStart: isDate.start, dataEnd: isDate.end,
            category: choiceSection, type: choiceMaterials
        }))
        setVisibleResult(true)
    }
    const handleSearchInput = (e: any) => {
        setSearch(e.target.value)
    }
    const handleSections = (value: string) => {
        setChoiceSections(value)
    }
    const handleMaterials = (value: string) => {
        setChoiceMaterials(value)
    }

    return (
        <Layouts page={!isXSmall && 'search'}>
            <div className={isXSmall ? "container-search" : "container-search-mobile"}>
                <Search
                    isXSmall={isXSmall}
                    handleSearchInput={handleSearchInput}
                    handleSearch={handleSearch}
                    handleVisible={handleVisible}
                    visibleFilter={visibleFilter}
                    visible={visible}
                    handleDate={handleDate}
                    isDate={isDate}
                    handleChange={handleChange}
                    filteredDate={filteredDate}
                    handleSections={handleSections}
                    filteredSections={dataLists.length !== 0 ? dataLists.categories : []}
                    handleMaterials={handleMaterials}
                    filteredMaterials={dataLists.length !== 0 ? dataLists.types : []}
                />
                {
                    searchData.length !== 0 &&
                    <>
                        <div>
                            {
                                searchData.data.length > 0 && visibleResult &&
                                <div className={"text16 title-search"}>Результаты поиска</div>
                            }
                        </div>

                        <div> {
                            searchData.data.length === 0 && visibleResult &&
                            <div className={"text16 title-search"}>Ничего не найдено...</div>
                        }
                        </div>
                    </>
                }
                {searchData.length !== 0 &&
                    searchData.map((item: any, i: number) =>
                        <div key={i}>
                            <Row gutter={[16, 16]}>
                                {item.small1 !== undefined &&
                                    <Col  xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <ResultSearchLeft searchData={item.small1}/>
                                    </Col>

                                }
                                {item.small2 !== undefined &&
                                     <Col  xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <ResultSearchRight searchData={item.small2}/>
                                     </Col>
                                }
                            </Row>
                            <Divider/>
                        </div>
                    )
                }
            </div>
        </Layouts>
    );
};

export default SearchPage;