import React from 'react';
import {Flex, Input, Select} from "antd";
import {NavLink} from "react-router-dom";
import {RouteNames} from "../../router/names";
import arrow from "../../assets/image/arrow.png";
import DatePickers from "../datePicker";


const Search = (props: any) => {
    return (
        <>
            <Flex justify={"space-between"} style={{marginBottom: 8}}>
                <div className={"text32"}>Поиск</div>
                <NavLink to={RouteNames.MAIN}>
                    <Flex className={"text16"} gap={12} style={{marginTop: 7, color: '#737576'}}>
                        {props.isXSmall && 'Закрыть'}
                        <div>&#10006;</div>
                    </Flex>
                </NavLink>
            </Flex>
            <Flex className={"text16"} style={{marginBottom: 32}}>Материалов: 2 456</Flex>
            <Flex gap={18} style={props.isXSmall ? {marginBottom: 20} : {marginBottom: 0}} vertical={!props.isXSmall}>
                <Input  className={"input-search text16"} placeholder={"Поиск"}
                       onChange={(e) => props.handleSearchInput(e)} allowClear/>
                {props.isXSmall ?
                    <button className={"button-search"} onClick={props.handleSearch}>Найти</button>
                    :
                    <Flex gap={8} onClick={() => props.handleVisible()} style={{width: '25%'}}>
                        <div>Фильтры</div>
                        <img src={arrow} className={"arrow-search"} alt={"arrow"}/>
                    </Flex>
                }
            </Flex>

            {props.visibleFilter &&
            <Flex style={{marginBottom: 20}} gap={24} vertical={!props.isXSmall}>
                {props.visible ?
                    <DatePickers visible={props.visible} handleDate={props.handleDate}/>
                    :
                    <Select
                        value={
                            props.isDate.start === null ? null
                                : props.isDate.end === null ? `${props.isDate.start}`
                                : `${props.isDate.start} - ${props.isDate.end}`
                        }
                        size={"large"}
                        placeholder={"Все время"}
                        style={{width: '100%', height: 48}}
                        onChange={props.handleChange}
                        options={props.filteredDate.map((item: any) => ({
                            value: item,
                            label: item,
                        }))}
                    />
                }
                <Select
                    placeholder={"Все разделы"}
                    style={{width: '100%', height: 48}}
                    size={"large"}
                    allowClear
                    onChange={(e) => props.handleSections(e)}
                    options={props.filteredSections.map((item: any) => ({
                        value: item.id,
                        label: item.name
                    }))}
                />
                <Select
                    placeholder={"Все материалы"}
                    style={{width: '100%', height: 48}}
                    size={"large"}
                    allowClear
                    onChange={props.handleMaterials}
                    options={props.filteredMaterials.map((item: any) => ({
                        value: item.id,
                        label: item.name
                    }))}
                />
            </Flex>

            }
            {!props.isXSmall &&
                <Flex className={"box-button-search"}>
                    <button className={"button-search"} onClick={props.handleSearch}>Найти</button>
                </Flex>
            }
        </>
    );
};

export default Search;