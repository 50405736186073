const info = {
    data: [
        {id: 1, label: 'О компании', value: 'about '},
        {id: 2, label: 'Контактная информация', value: 'information'},
        {id: 3, label: 'Размещение рекламы', value: 'placement'},
    ],
    data1: [
        {id: 4, label: 'Правила использования материалов', value: 'rules '},
        {id: 5, label: 'Политика конфиденциальности', value: 'privacy_policy'}
    ]
}
export default info;