import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchPolitics} from "../api/polidics";
import {GetPoliticsDataType} from "../api/polidics/types/politics_type";

interface IPoliticsState {
    data: Array<GetPoliticsDataType>,
    total: number
}

const initialState: IPoliticsState = {
    data: [],
    total: 0
};
export const politicsD = createAsyncThunk(
    'politicsD',
    async (payload: number) => {
        let result: any | number = [];
        const response: any = await fetchPolitics(payload);
        if (response.status === 200) {
            result.data = response.data.data.posts
            result.total = response.data.data.total
            return result
        }else {
            return []
        }
    }
)

const politicsSlice = createSlice({
    name: 'politics',
    initialState,
    reducers: {
        setDataPoliticsReducers: (state, action) => {
            state.data = action.payload
        },
        setTotalReducers: (state, action) => {
            state.total = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(politicsD.fulfilled, (state, action) => {
            state.data = action.payload
        })
    },
});
export default politicsSlice.reducer;
export const { setDataPoliticsReducers,setTotalReducers } = politicsSlice.actions;