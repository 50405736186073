import React, {useEffect, useState} from 'react';
import {RootState, useStoreDispatch} from "../../redux";
import {useSelector} from "react-redux";
import {economyD, setDataEconomyReducers, setTotalReducers} from "../../redux/economyData";
import Layouts from "../../layout";
import Content from "../../components/content";

const Economy = () => {
    const dispatch = useStoreDispatch();
   // const dataEconomyxx: any = useSelector((state: RootState) => state.economyData.data);
   // const totalReducers: number = useSelector((state: RootState) => state.economyData.total);
    const [fetching, setFetching] = useState(true);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [dataEconomy, setDataEconomy]: any[] = useState<any[]>([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (fetching){
            setLoading(true)
            dispatch(economyD(currentPage)).then(response =>{
                if (response.payload.data.length !== 0) {
                    setDataEconomy([...dataEconomy, ...response.payload?.data])
                    setTotal(response.payload.total)
                    setCurrentPage(prevState => prevState + response.payload?.data.length)
                   // dispatch(setTotalReducers(response.payload?.total))
                   // dispatch(setDataEconomyReducers([...dataEconomy, ...response.payload?.data]))
                }
                setLoading(false)
            }).finally(() => setFetching(false))
        }
        document.addEventListener('scroll',scrollHandler);
        return function () {
            document.removeEventListener("scroll", scrollHandler)
        }
    }, [fetching]);


    const scrollHandler = (e: any) => {
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) -1000 < 100 &&
            dataEconomy.length < total) {
            setFetching(true)
        }
    }

    return (
            <Layouts>
                <Content data={dataEconomy} loading={loading} label={"Экономика"}/>
            </Layouts>
    );
};

export default Economy;