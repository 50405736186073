import request from '../request';
import {GetResponseSearchData} from "./types/search_types";
import {GetResponseListsData} from "./types/lists_type";


export function fetchSearchApi(data: any){
    return request<GetResponseSearchData>({
        url: 'https://russianworld.press/api/search',
        method: 'post',
        data
    });
}
export function fetchListsApi(){
    return request<GetResponseListsData>({
        url: 'https://russianworld.press/api/lists',
    });
}