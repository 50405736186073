import React from 'react';
import './style.scss'
import {Col, Flex} from "antd";
import {useIsXSmall} from "../../hooks/useIsXSmall";
import tg_blue from "../../assets/image/tg_blue.png";
import img_tg_banner from "../../assets/image/img_tg_banner.png";

const TgBanner = () => {
    const isXSmall = useIsXSmall();
    const tgLink = () =>{
        window.open("https://t.me/originalRussianWorld", "_blank");
    }

    return (
        <Flex vertical={!isXSmall} className={"card-banner"}>
            <Col xs={24} sm={24} md={17} lg={17} xl={17} style={{padding: '18px 32px'}}>
                <div className={"text-tg-banner"}>Ещё больше свежих новостей в нашем ТГ-канале</div>
                <div className={"sub-text-tg-banner"}
                style={{paddingBottom: 13}}>Только главное, без информационного шума</div>
                <button onClick={tgLink} style={{cursor: "pointer"}}>
                    <img src={tg_blue} width={20} alt={"tg_blue"}/>
                    Перейти
                </button>
            </Col>
            <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                <Flex justify={"center"} align={"flex-end"} style={{height: '100%'}}>
                    <img src={img_tg_banner} alt={"img_tg_banner"} style={isXSmall ? {width: '14vw'} : {}}/>
                </Flex>
            </Col>
        </Flex>
    );
};

export default TgBanner;