import React from 'react';
import {Col, Flex, Row, Skeleton, Space} from "antd";

const LeftSkeleton = (props: any) => {
    return (
        <div style={{marginRight: 20}}>
            {
                Array.from({length: props.col},(v,i) =>
                    <Skeleton active key={i}/>
                )
            }
        </div>
    );
};

export default LeftSkeleton;