import React, {FC} from 'react';
import {Props} from "../../type";
import {Flex} from "antd";
import {NavLink} from "react-router-dom";
import moment from "moment";


const NewsRight: FC<Props> = (props: Props) => {
    return (
        <>
            {
                props.data.map((item: any, i: number) =>
                    <div className={"card-right-podcast"} key={i}>
                        <Flex vertical className={"box-podcast-text"}>
                            <NavLink to={item.link} target="_blank">
                                <div className={"text-podcast"}>{item.text}</div>
                            </NavLink>
                            <div className={"box-time-type-podcast"}>
                                <div className={"time-type"}>{moment(props.data.dateTime).format('hh.mm')}</div>
                                <div className={"time-type"}>&bull;</div>
                                <div className={"time-type"}>{item.type.name}</div>
                            </div>
                        </Flex>
                    </div>
                )
            }
        </>
    );
};

export default NewsRight;