import React, {FC, useEffect, useRef, useState} from 'react';
import {Avatar, Flex} from "antd";
import {EyeFilled} from "@ant-design/icons/lib";
import './style.scss'
import {Props} from "../../type";
import {useIsXSmall} from "../../hooks/useIsXSmall";
import {NavLink} from "react-router-dom";
import play from "../../assets/image/play.png"
import {RouteNames} from "../../router/names";
import {useSelector} from "react-redux";
import {RootState, useStoreDispatch} from "../../redux";
import {setClickViews, setHiddenPay} from "../../redux/newsData";

const NewsCenter: FC<Props> = (props: Props) => {
    const isXSmall = useIsXSmall();
    const [isLoadPlay, setIsLoadPlay] = useState(false);
    const [hiddenPlay, setHiddenPlay] = useState(true);
    const isSound: boolean = useSelector((state: RootState) => state.drawer.sound);
    const dataNews: any = useSelector((state: RootState) => state.newsData.data);
    const dispatch = useStoreDispatch()

    const handlePlay = async (item: any) => {
        const videos = document.querySelectorAll('video');
        videos.forEach(function (video) {
            if (video.src === item.adr) {
                video.play()
                video.controls = true
                setHiddenPlay(false)
                setIsLoadPlay(true)
            } else {
                video.pause()
                video.controls = false
            }
        });

        dataNews.dataCenter.map((el: any,i:number) => {
            if (el.id === item.item.id){
                const obj = Object.freeze(dataNews.dataCenter[i])
            }
        })

    }
    const handleViews = (id: number) =>{
        dispatch(setClickViews({postID: id}))
    }

    return (
        <>
            {
                props.data.map((item: any, i: number) =>
                    <Flex vertical className={isXSmall ? "card-center" : "card-center-mobile"} key={i}
                          justify={"center"} align={"center"}>

                        {item.media !== null &&
                        new URL(item.media.toUpperCase()).pathname.split(".").pop() === 'MP4'.toUpperCase() ?
                            <Flex
                                style={{width: '100%', position: "relative"}}>
                                <video src={item.media} poster={item.imgPreview}
                                       className={isXSmall ? "video-center" : "video-center-mobile"}
                                       width={'100%'} muted={!isSound}/>
                                {item.pay &&
                                  <>
                                     <Flex onClick={() => handlePlay({adr: item.media, item: item})} gap={12}
                                          justify={"flex-start"} align={"flex-start"}
                                          style={{color: "white", position: "absolute", padding: 20, cursor: "pointer"}}
                                          className={"text14 box-play"}>
                                        <img src={play} alt={"play"}/>Видео
                                     </Flex>
                                     <Flex className={"text14 time-video"}>9:56</Flex>
                                  </>
                                }
                            </Flex>
                            :
                            <img src={item.imgPreview} alt={""} className={isXSmall ? 'video' : "video-mobile"}/>
                        }

                        <div style={isXSmall ? {width: '91%', height: 168} : {width: '100%', height: 151}}>
                            <NavLink to={{
                                pathname: `/detail/${item.id}`
                            }} state={[item, RouteNames.MAIN, {data: props.data}]} rel="noopener noreferrer"
                                     onClick={() =>handleViews(item.id)}>
                                <div className={"title-card-center"}>{item.text}</div>
                            </NavLink>

                            <Flex className={"box-time-type-center"}>
                                <div className={"time-type"}>{item.time}</div>
                                <div className={"time-type"}>&bull;</div>
                                <div className={"time-type"}>{item.type.name}</div>
                            </Flex>
                            <Flex className={"box-user-avatar-views-center"}>
                                <div className={"box-user-avatar"}>
                                    {
                                        item.user.avatar === null ?
                                            <Avatar size={20}/> :
                                            <div className={"time-type"}>
                                                <Avatar src={item.user.avatar} alt={"avatar"} size={20}/>
                                            </div>
                                    }
                                    <div className={"text-user-center"}>{item.user.name}</div>
                                </div>
                                <div className={"box-views"}>
                                    <EyeFilled className={"views-img"}/>
                                    <div className={"time-type"}>{item.views}</div>
                                </div>
                            </Flex>
                        </div>
                    </Flex>
                )
            }
        </>
    );
};

export default NewsCenter;