import React, {FC} from 'react';
import {Avatar, Col, Flex, Row} from "antd";
import {PropsItem} from "../../type";
import BoxTimeType from "../../components/news/BoxTimeType";
import {EyeFilled} from "@ant-design/icons/lib";
import {NavLink} from "react-router-dom";
import {RouteNames} from "../../router/names";
import {useStoreDispatch} from "../../redux";
import {setClickViews} from "../../redux/newsData";
import {useIsXSmall} from "../../hooks/useIsXSmall";

const BigBlock: FC<PropsItem> = (props: PropsItem) => {
    const dispatch = useStoreDispatch()
    const isXSmall = useIsXSmall();

    const handleViews = (id: number) =>{
        dispatch(setClickViews({postID: id}))
    }
    return (
        <div style={{padding: '20px 0', borderBottom: '1px solid #959697'}} key={props.i}>
            <Flex vertical={!props.IsXSmall}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Flex>
                        <img src={props.item.imgPreview} alt={""} width={'100%'} height={props.IsXSmall ? 277 : 205}
                             className={'img-big'}/>
                    </Flex>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Flex style={props.IsXSmall ? {padding: '64px 0 0 25px', height: '100%'} : {paddingTop: 16, height: '100%'}}
                           vertical>
                        <NavLink to={{
                            pathname: `/detail/${props.item.id}`,
                        }} state={[props.item, RouteNames.GEOPOLITICS, {data: props.data}]} rel="noopener noreferrer"
                                 onClick={() =>handleViews(props.item.id)}>
                            <div className={props.IsXSmall ? "text22 overflow" : "text22 overflow2"}
                                 style={{marginBottom: 29}}>{props.item.text}</div>
                        </NavLink>
                        <BoxTimeType data={props.item}/>
                        <Flex>
                            <Flex gap={8} style={{width: '100%', marginTop: 8}}>
                                {props.item.user.avatar === null ? (
                                        <Avatar size={20}/>) :
                                    (<div
                                        className={"time-type"}>
                                        <Avatar src={props.item.user.avatar} alt={"avatar"} size={20}/>
                                    </div>)
                                }
                                <div>{props.item.user.name}</div>
                            </Flex>
                        </Flex>
                        <Flex align={"flex-end"} gap={4} className={isXSmall ? 'box--views' : 'box--views-mobile'}>
                            <EyeFilled className={"views-img"}/>
                            <div className={"time-type"}>{props.item.views}
                            </div>
                        </Flex>
                    </Flex>
                </Col>
            </Flex>
        </div>
    );
};

export default BigBlock;