import request from '../request';
import {GetResponseNewsData} from './types/news_type';


export function fetchNews(){
    return request<GetResponseNewsData>({
        url: 'https://russianworld.press/api/getNews',
    });
}
export function fetchColViews(data: {}){
    return request<GetResponseNewsData>({
        url: 'https://russianworld.press/api/view-post',
        method: 'post',
        data
    });
}