import React from 'react';
import {Divider, Drawer} from 'antd';
import './style.scss'
import menu from '../../components/menu/menu'
import {NavLink} from "react-router-dom";
import {useStoreDispatch} from "../../redux";
import {setDrawer} from "../../redux/drawer";

const Drawers = (props: any) => {
    const dispatch = useStoreDispatch()

    const handleDrawer = () => {
        dispatch(setDrawer(false))
    }
    return (
        <Drawer
            placement="left"
            width={'58%'}
            closable={false}
            onClose={props.onCloseDrawer}
            open={props.drawer}>
            <div className={"video-title-mobile drawer-title"}>РМ</div>
            <Divider/>
            <ul className={"box-menu-drawer"}>
                {
                    menu.data.map((item, i) =>
                        <NavLink to={{pathname: `${item.link}`}} style={{width: '100%'}}
                                 onClick={handleDrawer}  key={item.id}>
                            <div>
                                <li className={"text24"}>{item.label}</li>
                                { i + 1 !== menu.data.length &&
                                    <div className={"drawer-divider"}/>
                                }
                            </div>
                        </NavLink>
                    )
                }
            </ul>
        </Drawer>
    );
};

export default Drawers;