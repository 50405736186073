import React from 'react';
import {Col, Flex, Row, Skeleton, Space} from "antd";

const GeoSkeleton = (props: any) => {
    return (
        <>
            {
                Array.from({length: props.col}, (v, i) =>
                    <Flex vertical gap={20} key={i} style={{width: '100%'}}>
                        <Row >
                            <Col span={12}>
                                <Space>
                                    <Skeleton.Input active size={'large'}/>
                                    <Skeleton.Avatar active shape={'square'} size={'large'}/>
                                </Space>
                                <Col span={22}>
                                    <Skeleton active/>
                                </Col>
                            </Col>
                            <Col span={12}>
                                <Space>
                                    <Skeleton.Input active size={'large'}/>
                                    <Skeleton.Avatar active shape={'square'} size={'large'}/>
                                </Space>
                                <div>
                                    <Skeleton active/>
                                </div>
                            </Col>
                        </Row>
                        <Col span={24}>
                        <Flex align={'center'} gap={10} style={{marginBottom: 20}}>
                            <Skeleton.Image/>
                            <Skeleton active/>
                        </Flex>
                        </Col>
                    </Flex>
                )
            }
        </>
    );
};

export default GeoSkeleton;