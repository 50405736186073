import React, {useState} from 'react';
import {Col, Flex, Form, Input, Modal} from "antd";
import vk_login from "../../../assets/image/vk_login.png";
import google_login from "../../../assets/image/google_login.png";
import yandex_login from "../../../assets/image/yandex_login.png";
import logo from "../../../assets/image/logo.png";
import "./style.scss"
import {NavLink, useNavigate} from "react-router-dom";
import {RouteNames} from "../../../router/names";
import back_left from '../../../assets/image/back_left.png';


const Registration = (props: any) => {
    const [phone, setPhone] = useState('');

    const navigate = useNavigate()

    const onFinish = () => {
        navigate(RouteNames.CONFIRM, { state: { isXSmall: props.isXSmall } })
    };
    return (
        <>
            {!props.isXSmall ?
                <>
                    <div className={"paper"}/>
                    <div className={"login-logo"}>
                        <Flex style={{marginBottom: 57}}>
                            <Col span={3}>
                                <NavLink to={RouteNames.MAIN} style={{marginLeft: 20}}>
                                    <img src={back_left} alt={"back_left"}/>
                                </NavLink>
                            </Col>
                            <Col style={{width: "100%"}}>
                                <img src={logo} style={{width: 148}}
                                     className={props.isXSmall ? 'logo' : "logo-mobile"} alt={"logo"}/>
                            </Col>
                            <Col span={3}/>
                        </Flex>
                        <Flex vertical gap={8} style={{marginBottom: 40,alignItems: "center"}}>
                            <div className={"text22 col"}>Авторизация</div>
                            <div className={"text14 col"}>Войдите на сайт любым удобным способом</div>
                        </Flex>
                        <Form onFinish={onFinish} >
                            <Flex gap={16} vertical justify={"center"} align={"center"}>
                                <Form.Item name="phone" style={{margin: 0,width: '100%',display: "flex",placeContent: "center"}}
                                           rules={[{required: true, message: 'Не указан номер телефона'}]}>
                                    <Input placeholder={"Телефон"} value={phone || ""} className={'input-login'}
                                         count={{ max: 6}}/>
                                </Form.Item>
                                <Form.Item name="btn">
                                        <button className={'button text16'} style={{width: 342}}>Войти</button>
                                </Form.Item>
                            </Flex>
                        </Form>
                        <Flex className={"col-social-login"} vertical={!props.isXSmall} >
                            <Flex gap={11} className={"box-social-login"}>
                                <img src={vk_login} alt={'vk_login'} width={32} height={32}/>
                                <div className={"text16"}>VK ID</div>
                            </Flex>
                            <Flex gap={11} className={"box-social-login"}>
                                <img src={google_login} alt={'google_login'} width={32} height={32}/>
                                <div className={"text16"}>Google</div>
                            </Flex>
                            <Flex gap={11} className={"box-social-login"}>
                                <img src={yandex_login} alt={'yandex_login'} width={32} height={32}/>
                                <div className={"text16"}>Яндекс</div>
                            </Flex>
                        </Flex>
                    </div>
                </>
                :
                <Modal
                    width={600}
                    open={props.isModalOpen}
                    centered
                    className={"modal"}
                    onCancel={props.handleCancel}
                    okButtonProps={{style: {display: 'none'}}}
                    cancelButtonProps={{style: {display: 'none'}}}>
                    <Form onFinish={props.login} initialValues={{phone: phone}}
                          style={!props.isXSmall ? {height: '100%'} : {height: "auto"}}>
                        <Flex justify={"center"} vertical align={"center"}>
                            <div className={"text32"} style={{marginBottom: 20}}>Авторизация</div>
                            <div className={props.isXSmall  ? "text16" : "text14"} style={{marginBottom: 20}}>Войдите на
                                сайт любым удобным способом
                            </div>
                            <Flex gap={16} style={{marginBottom: 32}} vertical={!props.isXSmall}>
                                <Form.Item name="phone" rules={[{required: true, message: 'Не указан номер телефона'}]}>
                                    <input placeholder={"Телефон"} value={phone || ""} className={'input-login text16'}/>
                                </Form.Item>
                                <Form.Item name="btn">
                                    <button className={'button text16'}>Войти</button>
                                </Form.Item>
                            </Flex>
                            <Flex gap={69} style={{marginBottom: 15}} vertical={!props.isXSmall}>
                                <Flex gap={12} style={{cursor: "pointer"}}>
                                    <img src={vk_login} alt={'vk_login'} width={32} height={32}/>
                                    <div>VK ID</div>
                                </Flex>
                                <Flex gap={12} style={{cursor: "pointer"}}>
                                    <img src={google_login} alt={'google_login'} width={32} height={32}/>
                                    <div>Google</div>
                                </Flex>
                                <Flex gap={12} style={{cursor: "pointer"}}>
                                    <img src={yandex_login} alt={'yandex_login'} width={32} height={32}/>
                                    <div>Яндекс</div>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Form>
                </Modal>
            }
        </>
    );
};

export default Registration;