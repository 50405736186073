import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchGeoPolitics} from "../api/geopolitics";
import {GetGeoPoliticsDataType} from "../api/geopolitics/types/geopolitics_type";

interface IGeoPoliticsState {
    data: Array<GetGeoPoliticsDataType>,
}

const initialState: IGeoPoliticsState = {
    data: [],
};
export const geoPoliticsD = createAsyncThunk(
    'geoPoliticsD',
    async (currentPage: number) => {
        const response: any = await fetchGeoPolitics(currentPage);
        Object.assign(response.data.data.posts,{
            total: response.data.data.total,
            len: response.data.data.posts.length
        })
        return response.data.data.posts
    }
)

const geoPoliticsSlice = createSlice({
    name: 'geoPoliticsD',
    initialState,
    reducers: {
        setDataGeoPoliticsReducers: (state, action) => {
            state.data = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(geoPoliticsD.fulfilled, (state, action) => {
            state.data = action.payload
        })
    },
});
export default geoPoliticsSlice.reducer;
export const { setDataGeoPoliticsReducers } = geoPoliticsSlice.actions;