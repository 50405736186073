import React, {useEffect, useState} from 'react';
import {RootState, useStoreDispatch} from "../../redux";
import {useSelector} from "react-redux";
import {opinionsD, setDataOpinionsReducers, setTotalReducers} from "../../redux/opinionsData";
import Layouts from "../../layout";

import Content from "../../components/content";

const Opinions = () => {

    const dispatch = useStoreDispatch();
  //  const dataOpinionsxx: any = useSelector((state: RootState) => state.opinionsData.data);
   // const totalReducers: number = useSelector((state: RootState) => state.opinionsData.total);
    const [fetching, setFetching] = useState(true);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [dataOpinions, setDataOpinions]: any[] = useState<any[]>([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (fetching){
            setLoading(true)
            dispatch(opinionsD(currentPage)).then(response =>{
                if (response.payload.data.length !== 0) {
                    setDataOpinions([...dataOpinions, ...response.payload.data])
                    setCurrentPage(prevState => prevState + response.payload.data.length)
                    setTotal(response.payload.total)
                    //dispatch(setTotalReducers(response.payload.total))
                    //dispatch(setDataOpinionsReducers([...dataOpinions, ...response.payload.data]))
                }
                setLoading(false)
            }).finally(() => setFetching(false))
        }
        document.addEventListener('scroll',scrollHandler);
        return function () {
            document.removeEventListener("scroll", scrollHandler)
        }
    }, [fetching]);

    const scrollHandler = (e: any) => {
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) -1000 < 100 &&
            dataOpinions.length < total) {
            setFetching(true)
        }
    }

    return (
        <Layouts>
          <Content data={dataOpinions} loading={loading} label={"Мнения"}/>
        </Layouts>
    );
};

export default Opinions;