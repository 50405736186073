

export enum RouteNames {
    LOGIN = '/login',
    CONFIRM = '/confirm',
    REGISTRATION = '/registration',
    MAIN = '/',
    DETAIL = '/detail/:id',
    POLITICS = '/politics',
    GEOPOLITICS = '/geopolitics',
    SEARCH = '/search',
    ECONOMY = '/economy',
    LOGISTICS = '/logistics',
    OPINIONS = '/opinions',
    NOTFOUND = '*'
}