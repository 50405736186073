import React, {FC} from 'react';
import {Avatar, Col, Divider, Flex} from "antd";
import BoxTimeType from "../../components/news/BoxTimeType";
import {PropsItem} from "../../type";
import {EyeFilled} from "@ant-design/icons/lib";
import {NavLink} from "react-router-dom";
import {RouteNames} from "../../router/names";
import play from "../../assets/image/play.png"
import "./style.scss"
import {useStoreDispatch} from "../../redux";
import {setClickViews} from "../../redux/newsData";
import {useIsXSmall} from "../../hooks/useIsXSmall";


const SmailBlockLeft: FC<PropsItem> = (props: PropsItem) => {
    const dispatch = useStoreDispatch()
    const isXSmall = useIsXSmall();

    const handleViews = (id: number) =>{
        dispatch(setClickViews({postID: id}))
    }
    return (
        <Col className={props.IsXSmall ? "col--geo" : "col--geo-mobile"}
             style={props.counter === 1 && isXSmall ?
                 {borderRight: '1px solid',paddingRight: 20}:
                 {paddingLeft: 20}}>
            <Flex vertical>
                <Flex gap={16}>
                    <NavLink to={{
                        pathname: `/detail/${props.item.id}`,
                    }} state={[props.item, RouteNames.GEOPOLITICS, {data: props.data}]} rel="noopener noreferrer"
                             onClick={() =>handleViews(props.item.id)} style={{width: '90%'}}>
                        <div className={"text16 overflow"}>{props.item.text}</div>
                    </NavLink>
                    {props.item.imgPreview !== null &&
                        <>
                        <img src={props.item.imgPreview} alt={''} width={63}
                           height={63} style={{objectFit: 'cover'}} />
                            {
                                new URL(props.item.media.toUpperCase()).pathname.split(".").pop() === 'MP4'.toUpperCase() &&
                                <img src={play} alt={"play"} width={20} className={"img-pay"}/>
                            }
                         </>
                    }
                </Flex>
                <Flex style={{marginTop: 16, paddingLeft: 0}}>
                    <Flex style={{paddingLeft: 0}}>
                        <BoxTimeType data={props.item}/>
                    </Flex>
                </Flex>
                <Flex style={{paddingTop: 10, marginBottom: 40}} justify={"space-between"}>
                    <Flex gap={8}>
                        {props.item.user.avatar === null ? (
                                <Avatar size={20}/>) :
                            (<div className={"time-type"}>
                                <Avatar src={props.item.user.avatar} alt={"avatar"} size={20}/>
                            </div>)
                        }
                        <div>{props.item.user.name}</div>
                    </Flex>
                    <Flex align={"center"} gap={4}>
                        <EyeFilled className={"views-img"}/>
                        <div className={"time-type"}>{props.item.views}</div>
                    </Flex>
                </Flex>
            </Flex>
        </Col>

    )
};

export default SmailBlockLeft;