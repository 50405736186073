import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {GetEconomyDataType} from "../api/economy/types/economy_type";
import {fetchEconomy} from "../api/economy";

interface IEconomyState {
    data: Array<GetEconomyDataType>,
    total: number
}

const initialState: IEconomyState = {
    data: [],
    total: 0
};
export const economyD = createAsyncThunk(
    'economyD',
    async (payload: number) => {
        let result: any | number = [];
        const response: any = await fetchEconomy(payload);

        if (response.status === 200) {
            result.data = response.data.data.posts
            result.total = response.data.data.total
            return result
        }else {
            return []
        }
    }
)

const economySlice = createSlice({
    name: 'economy',
    initialState,
    reducers: {
        setDataEconomyReducers: (state, action) => {
            state.data = action.payload
        },
        setTotalReducers: (state, action) => {
            state.total = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(economyD.fulfilled, (state, action) => {
            state.data = action.payload
        })
    },
});
export default economySlice.reducer;
export const { setDataEconomyReducers,setTotalReducers } = economySlice.actions;