import {configureStore} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import drawer from "./drawer";
import geoPoliticsData from "./geoPoliticsData";
import politicsData from "./politicsData"
import newsData from "./newsData";
import economyData from "./economyData";
import logisticsData from "./logisticsData";
import opinionsData from "./opinionsData";
import searchData from "./searchData";

export const store = configureStore({
    reducer: {
        drawer,newsData,geoPoliticsData,politicsData,economyData,logisticsData,opinionsData,searchData
    },
    devTools: true,
});
export const useStoreDispatch = () => useDispatch<typeof store.dispatch>()
export type RootState = ReturnType<typeof store.getState>