import React, {FC, useEffect, useState} from 'react';
import {Avatar, Carousel, Col, Divider, Flex, Row} from "antd";
import {useIsXSmall} from "../../hooks/useIsXSmall";
import views from "../../assets/image/views.png";
import tg_black from "../../assets/image/tg_black.png";
import vk_black from "../../assets/image/vk_black.png";
import ok_black from "../../assets/image/ok_black.png";
import chain_back from "../../assets/image/chain_back.png";
import {PropsItemDetail} from "../../type";
import moment from "moment";
import parse from "html-react-parser";
import {useSelector} from "react-redux";
import {RootState} from "../../redux";
import play from "../../assets/image/play.png";


const CardPolitics: FC<PropsItemDetail> = (props: PropsItemDetail) => {

    const isXSmall = useIsXSmall();
    const sound: boolean = useSelector((state: RootState) => state.drawer.sound);

    useEffect(() => {
        const videos = document.querySelectorAll('video');
        if (videos.length !== 0) {videos[0].volume = 0.5}
    }, []);

    const handlePlay = async (item: any) => {
        const videos = document.querySelectorAll('video');
        videos.forEach( (video) =>{
            if (video.src === item) {
                video.play()
                video.controls = true
            }else {
                video.pause()
                video.controls = false
            }
        })
    }

    return (
        <div>
            {props.data.length !== 0 &&
            <>
                <div className={isXSmall ? "h1700" : "h1700-mobile"} style={{marginBottom: 24}}>
                    {props.data.title}
                </div>
                <Flex gap={9} style={{marginBottom: 24}}>
                    <Avatar src={props.data.user.avatar} size={44}/>
                    <Flex vertical gap={4}>
                        <div className={'text16'}>{props.data.user.name}</div>
                        <Flex gap={10}>
                            {props.data.dateTime !== null &&
                            <div className={'date'}>{moment(props.data.dateTime).format('DD.MM.YYYY')}</div>
                            }
                            <div className={"time-type"}>&bull;</div>
                            <div className={'date'}>{props.data.type.name}</div>
                        </Flex>
                    </Flex>
                </Flex>
                <Col xs={24} sm={24} md={24} lg={18} xl={18} style={{marginBottom: 32}}>
                    <Flex vertical={!isXSmall} gap={10}>
                        <Row className={isXSmall ? "mb0" : "mb24"}>
                            <Flex gap={10}>
                                <img src={views} alt={"views"} width={20} height={20}/>
                                <div className={'text14'}>{props.data.views} просмотра</div>
                                {isXSmall &&
                                <Divider type={'vertical'} style={{background: '#959697', height: 21}}/>
                                }
                            </Flex>
                        </Row>
                        <Row>
                            <Flex gap={10}>
                                <div className={'text14'}>Поделиться:</div>
                                <Flex style={{position: 'relative', right: 2}} gap={4}>
                                    <img src={tg_black} alt={"tg_black"}
                                         width={isXSmall ? 24 : 32} height={isXSmall ? 24 : 32}/>
                                    <img src={vk_black} alt={"tg_black"}
                                         width={isXSmall ? 24 : 32} height={isXSmall ? 24 : 32}/>
                                    <img src={ok_black} alt={"ok_black"}
                                         width={isXSmall ? 24 : 32} height={isXSmall ? 24 : 32}/>
                                    <img src={chain_back} alt={"chain_back"}
                                         width={isXSmall ? 24 : 32} height={isXSmall ? 24 : 32}/>
                                </Flex>
                            </Flex>
                        </Row>
                    </Flex>
                </Col>

                {props.data.media !== undefined &&
                new URL(props.data.media.toUpperCase()).pathname.split(".").pop() === 'MP4'.toUpperCase() ?
                    <Flex  onClick={() => handlePlay(props.data.media)}  style={{width: '100%', position: "relative"}}>

                        <video src={props.data.media} poster={props.data.imgPreview} autoPlay={props.index === 0}
                               className={"video-politics"} muted={!sound} />

                        <Flex onClick={(el: any) => handlePlay(props.data.media)} gap={12}
                              justify={"flex-start"} align={"flex-start"}
                              style={{color: "white", position: "absolute", padding: 20, cursor: "pointer"}}
                              className={"text14 box-play"}>
                            <img src={play} alt={"play"}/>Видео
                        </Flex>
                        <Flex className={"text14 time-video"}>9:56</Flex>
                    </Flex>

                    :
                    <img src={props.data.imgPreview} className={"img-politics"} alt={"imgPreview"}/>
                }
                <div className={'text14'} style={{width: '79%', alignSelf: 'center', marginBottom: 39}}>
                    {props.data.type_author} {props.data.photo_author}
                </div>
                <Flex vertical className={"col-descript"}>
                    {
                        props.data.content.map((item: any, i: number) =>
                            <div  key={i} className={"box-descript"}>
                                <div className={'text18'}>
                                    {item.type === 'paragraph' &&
                                    <>{parse(item.data.text)}</>
                                    }
                                </div>
                                <div style={{display: "contents"}}>
                                    {item.type === 'image' &&
                                    <img src={item.data.file.url} alt={"img-descript"} className={"img-descript"}/>
                                    }
                                    {item.type === 'carousel' &&
                                    <Carousel style={{width: '63vw'}} autoplay>
                                        {
                                            item.data.map((item: any, i: number) =>
                                                <div style={{borderRadius: 16}}  key={i}>
                                                    <img src={item.url} alt={"img-descript"} className={"img-carousel"}/>
                                                </div>
                                            )
                                        }
                                    </Carousel>
                                    }
                                </div>
                            </div>
                        )
                    }
                </Flex>
            </>
            }
        </div>
    );
};

export default CardPolitics;