import React from 'react';
import Layouts from "../../layout";
import notFound from "../../assets/image/404.png"
import './style.scss'
import {Flex} from "antd";

const NotFound = () => {

    return (
        <Layouts>
            <Flex style={{width: '100%',height: '70vh'}} justify={"center"} align={"center"} className="image-wrapper">
                <img src={notFound} alt="" className="image-item" width={311} height={230}/>
                    <div className={"image-text"}>Ошибка! К сожалению, заправшиваемая страница не найдена</div>
            </Flex>
        </Layouts>
    );
};

export default NotFound;