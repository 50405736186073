import React, {FC, useEffect, useState} from 'react';
import {Col, Divider, Flex, Skeleton, Spin} from "antd";
import {NavLink} from "react-router-dom";
import {RouteNames} from "../../router/names";
import back_left from "../../assets/image/back_left.png";
import CardPolitics from "../../pages/politics/card";
import TgBanner from "../tg_banner";
import logo_mini from "../../assets/image/logo_mini.png";
import BlockSocial from "../block_social";
import {LoadingOutlined} from '@ant-design/icons';
import {useIsXSmall} from "../../hooks/useIsXSmall";
import {PropsContent} from "../../type";
import {setClickViews} from "../../redux/newsData";
import {useStoreDispatch} from "../../redux";


const Content: FC<PropsContent> = (props: PropsContent) => {
    const isXSmall = useIsXSmall();
    const [loading, setLoading] = useState<boolean>(true);
    const [visibleNoNews, setVisibleNoNews] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
            setVisibleNoNews(true)
        }, 2000);
    }, []);


    return (
        <>
            <Flex vertical={!isXSmall} style={isXSmall ? {marginTop: 40} : {marginTop: 0}}>
                <Col xs={24} sm={3} md={3} lg={3} xl={3} style={{height: 116, marginBottom: 26}}>
                    <Flex justify={"flex-start"} align={"flex-end"} style={{height: '28%'}}>
                        <NavLink to={RouteNames.MAIN}>
                            <img src={back_left} alt={"back_left"}/>
                        </NavLink>
                    </Flex>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                    <Flex vertical style={{marginBottom: 80}}>
                        <div className={"text44Tinos"} style={{marginBottom: 22}}>{props.label}</div>
                        {props.data.length > 0 ?
                            props.data.map((item: any, i: number) =>
                                i === 0 &&
                                <div key={i}>
                                    <CardPolitics data={item} index={i}/>
                                </div>
                            ) :
                            <Flex style={{width: '100%', marginTop: 40}} justify={"center"} align={"center"} vertical>
                                <Skeleton loading={loading}/>
                                {visibleNoNews &&
                                    <div className={"text18"}>Пока новостей нет</div>
                                }
                                <Divider/>
                            </Flex>
                        }
                        <div className={"text18"} style={isXSmall ?
                            {width: '79%', alignSelf: 'center'} : {width: '100%', alignSelf: 'center'}}>
                            <a href={"https://band.link/Zs3xh"} target="_blank" rel="noopener noreferrer">
                                <TgBanner/>
                            </a>
                        </div>
                    </Flex>
                    <Flex>
                        <Divider style={{color: "black", height: 1}} orientationMargin={50}>
                            <img src={logo_mini} alt={"logo_mini"} width={48} height={41}/>
                        </Divider>
                    </Flex>
                    {props.data.length > 0 &&
                    <Flex gap={7} style={{marginTop: 100, marginBottom: 20}}>
                        <div className={"breadcrumb"} style={{color: '#737576'}}>
                            Ещё по теме
                        </div>
                        <div className={"time-type"}>&bull;</div>
                        <div className={"breadcrumb"}>{props.label}</div>
                    </Flex>
                    }
                    <Flex vertical>
                        {props.data.length > 0 ?
                            props.data.map((item: any, i: number) => (
                                i > 0 &&
                                <Flex key={i} vertical>
                                    <Flex vertical>
                                        <CardPolitics data={item} index={i}/>
                                    </Flex>
                                </Flex>
                            ))
                            :
                            <Skeleton loading={loading}/>
                        }
                        <Flex style={{width: '100%', marginBottom: 40}} justify={'center'}>
                            <Spin spinning={props.loading}
                                  indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
                        </Flex>
                    </Flex>
                </Col>
                <Col xs={24} sm={3} md={3} lg={3} xl={3}>
                </Col>
            </Flex>
            <BlockSocial/>
        </>
    );
};

export default Content;