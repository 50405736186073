import React from 'react';
import {Button, Flex, Form, Input, Modal} from "antd";
import {useIsXSmall} from "../../hooks/useIsXSmall";

const Donation = (props: any) => {
    const isXSmall = useIsXSmall();

    return (
        <div>
            <Modal
                width={625}
                centered
                className={"modal"}
                okButtonProps={{style: {display: 'none'}}}
                cancelButtonProps={{style: {display: 'none'}}}
                open={props.isModalOpenDonation}
                onCancel={props.handleCancelDonation}>
                <Form onFinish={props.login} initialValues={{phone:  ''}}
                      style={!props.isXSmall ? {height: '100%'} : {height: "auto"}}>
                    <Flex justify={"center"} vertical align={"center"}>
                        <div className={"text32"} style={{marginBottom: 20, marginTop: 20}}>Поддержать проект</div>
                    </Flex>
                    <Flex  justify={"center"} align={"center"} >
                        <Flex vertical style={isXSmall ? {width: '81%'} : {}}>
                            <div className={"text16"} style={{textAlign: "center"}}>
                                Русский мир — некоммерческий проект. Мы развиваемся благодаря вам и всегда рады вашей поддержке.
                            </div>
                            <Flex gap={16} style={{marginTop: 32}} vertical={!props.isXSmall}>
                                <Flex vertical style={{width: '100%',background: "white",padding: 20}}>
                                    <div className={"text16"}
                                         style={{fontWeight: "bold",marginBottom: 20}}>Перевод по кнопке</div>
                                    <div className={"text14"}>Сколько</div>
                                    <Input suffix="&#8381;" style={{marginBottom: 20}}/>
                                    <Button style={{background: '#8b3ffd', color: 'white'}} block>Пожертвовать</Button>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>

                </Form>
            </Modal>
        </div>
    );
};

export default Donation;