import React, {useState} from 'react';
import {useIsXSmall} from "../../hooks/useIsXSmall";
import {Flex} from "antd";
import tg_blue from "../../assets/image/tg_blue.png";
import img_tg_banner from "../../assets/image/img_tg_banner.png";
import close_white from "../../assets/image/close_white.png";

const TgBannerMini = () => {
    const isXSmall = useIsXSmall();
    const [visible, setVisible] = useState(true);

    const tgLink = () =>{
        window.open("https://t.me/originalRussianWorld", "_blank");
    }

    const closeBanner = () =>{
        setVisible(false)
    }
    const closeBannerMini = () =>{
        setVisible(true)
    }

    return (
        <>
            {isXSmall ?
            <>
                {
                    visible ?
                        <Flex className={"card-banner-mini"} vertical>
                            <Flex className={"box--banner"} justify={"space-between"}>
                                <div className={"text-tg-banner"}>А ты подписался на ТГ канал?</div>
                                <Flex className={'box-close'} onClick={closeBanner}>
                                    <img src={close_white} alt={'close_white'} width={24} height={24}/>
                                </Flex>
                            </Flex>
                            <Flex>
                                <Flex vertical className={"box--sub-banner"}>
                                    <div className={"sub-text-tg-banner"}
                                         style={{paddingBottom: 13}}>Только главные новости, без информационного шума
                                    </div>
                                    <button onClick={tgLink} style={{cursor: "pointer"}}>
                                        <img src={tg_blue} width={20} alt={"tg_blue"}/>
                                        Перейти
                                    </button>
                                </Flex>
                                <Flex>
                                    <img src={img_tg_banner} alt={"img_tg_banner"} width={"100%"}/>
                                </Flex>
                            </Flex>
                        </Flex>
                        :
                        <Flex className={"mini-banner"} onClick={closeBannerMini}>
                            <div className={"text18"}>Телеграм канал</div>
                            <img src={img_tg_banner} width={'36%'} style={{marginTop: 13}} alt={"img_tg_banner"}/>
                        </Flex>
                }
            </>
                :
                <Flex className={"mini-banner"} onClick={tgLink}
                      style={{background: "#D34627", top: '86%',position: "fixed"}}>
                    <div className={"text18"} style={{color: "white"}}>Телеграм канал</div>
                    <img src={img_tg_banner} width={'36%'} style={{marginTop: 13}} alt={"img_tg_banner"}/>
                </Flex>
            }
        </>
    );
};

export default TgBannerMini;