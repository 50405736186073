import React from 'react';
import Header from "../components/header";
import { Layout } from 'antd';
import Footer from "../components/footer";
import {useIsXSmall} from "../hooks/useIsXSmall";

const Layouts = (props: any) => {
    const isXSmall = useIsXSmall();
    return (
        <Layout className={"layout"}>
            <div className={"paper"}/>
            <Header/>
            <div className={isXSmall ? 'container' : 'container-mobile'}>
                {props.children}
            </div>
            {typeof props.page !== undefined && props.page !== 'search' &&
                <Footer/>
            }
        </Layout>
    );
};

export default Layouts;