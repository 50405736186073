import React from 'react';
import {Avatar, Flex} from "antd";
import './style.scss'
import BoxTimeType from "../news/BoxTimeType";

const ResultSearchRight = (props: any) => {

    return (
        <Flex justify={"space-between"}>
            <div className={"box-img-search"}>
                <img src={props.searchData.imgPreview} className={"img-search"} alt={"img-search"}/>
            </div>
            <Flex vertical justify={"space-between"} gap={8}>
                <div className={"text16 box-text-search box-text-search--right"}>
                    {props.searchData.text}
                </div>
                <Flex vertical gap={8}>
                    <BoxTimeType data={props.searchData}/>
                    <Flex gap={8}>
                        {
                            props.searchData.user.avatar === null ?
                                <Avatar size={20}/> :
                                <div className={"time-type"}>
                                    <Avatar src={props.searchData.user.avatar} alt={"avatar"} size={20}/>
                                </div>
                        }
                        <div className={"text12"}>{props.searchData.user.name}</div>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ResultSearchRight;