import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import Layouts from "../../layout";
import {Col, Divider, Flex} from "antd";
import {useIsXSmall} from "../../hooks/useIsXSmall";
import {RouteNames} from "../../router/names";
import back_left from "../../assets/image/back_left.png";
import CardPolitics from "../politics/card";
import TgBanner from "../../components/tg_banner";
import logo_mini from "../../assets/image/logo_mini.png";
import BlockSocial from "../../components/block_social";

const Detail = () => {
    const params: any = useLocation().state[0]
    const link: any = useLocation().state[1]
    const yet: any = useLocation().state[2].data
    const isXSmall = useIsXSmall();
    const [yetLink, setYetLink] = useState("")

    useEffect(() => {
        params.category.name === "Геополитика" && setYetLink(RouteNames.GEOPOLITICS)
        params.category.name === "Политика" && setYetLink(RouteNames.POLITICS)
    }, []);


    return (
        <Layouts>
            <Flex vertical={!isXSmall} style={{position: 'relative', top: 20}}>
                <Col xs={24} sm={3} md={3} lg={3} xl={3} style={{height: 116,marginBottom: 26}}>
                    <Flex justify={"flex-start"} align={"flex-end"} style={{height: '65%'}}>
                        <NavLink to={link}>
                            <img src={back_left} alt={"back_left"}/>
                        </NavLink>
                    </Flex>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                    <Flex vertical style={{marginBottom: 80}}>
                        <CardPolitics data={params} index={1}/>
                        <div className={"text18"} style={isXSmall ?
                            {width: '79%', alignSelf: 'center'} : {width: '100%', alignSelf: 'center'}}>
                            <a href={"https://band.link/Zs3xh"} target="_blank" rel="noopener noreferrer">
                                <TgBanner/>
                            </a>
                        </div>
                    </Flex>
                    <Flex>
                        <Divider style={{color: "black", height: 1}} orientationMargin={50}>
                            <img src={logo_mini} alt={"logo_mini"} width={48} height={41}/>
                        </Divider>
                    </Flex>
                </Col>
            </Flex>
            <Flex gap={7} style={{marginTop: 100,marginBottom: 20,marginLeft: '12.8%'}}>
                <div className={"breadcrumb"} style={{color: '#737576'}}>
                    Ещё по теме
                </div>
                <div className={"time-type"}>&bull;</div>
                <NavLink to={yetLink}>
                    <div className={"breadcrumb"}>{params.category.name}</div>
                </NavLink>
            </Flex>
            <Flex vertical>
                {yet.length !== 0 &&
                  yet.map((item: any, i: number) =>
                    item.id !== params.id &&
                        <Flex style={{marginTop: 30}} key={i}>
                            <Col xs={0} sm={3} md={3} lg={3} xl={3} style={{height: 116}}/>
                            <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                                <Flex vertical>
                                    <CardPolitics data={item} index={i}/>
                                </Flex>
                            </Col>
                        </Flex>
                     )
                  }
            </Flex>
            <BlockSocial/>
        </Layouts>
    );
};

export default Detail;