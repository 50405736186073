import React, {useEffect, useState} from 'react';
import {RootState, useStoreDispatch} from "../../redux";
import {useSelector} from "react-redux";
import Layouts from "../../layout";
import Content from "../../components/content";
import {logisticsD, setDataLogisticsReducers,setTotalReducers} from '../../redux/logisticsData';

const Logistics = () => {
    const dispatch = useStoreDispatch();
  //  const dataLogisticsxx: any = useSelector((state: RootState) => state.logisticsData.data);
   // const totalReducers: number = useSelector((state: RootState) => state.logisticsData.total);
    const [fetching, setFetching] = useState(true);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [dataLogistics, setDataLogistics]: any[] = useState<any[]>([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (fetching){
            setLoading(true)
            dispatch(logisticsD(currentPage)).then(response =>{
                if (response.payload.data.length !== 0) {
                    setDataLogistics([...dataLogistics, ...response.payload?.data])
                    setCurrentPage(prevState => prevState + response.payload?.data.length)
                    setTotal(response.payload.total)
                   // dispatch(setTotalReducers(response.payload.total))
                   // dispatch(setDataLogisticsReducers([...dataLogistics, ...response.payload?.data]))
                }
                setLoading(false)
            }).finally(() => setFetching(false))
        }
        document.addEventListener('scroll',scrollHandler);
        return function () {
            document.removeEventListener("scroll", scrollHandler)
        }
    }, [fetching]);

    const scrollHandler = (e: any) => {
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) -1000 < 100 &&
            dataLogistics.length < total) {
            setFetching(true)
        }
    }

    return (
        <Layouts>
            <Content data={dataLogistics} loading={loading} label={"Логистика"}/>
        </Layouts>
    );
};

export default Logistics;