import React from "react";
import {RouteNames} from "./names";
import Registration from "../pages/authorization/registration";
import Main from "../pages/main";
import Detail from "../pages/main/detail";
import Politics from "../pages/politics";
import GeoPolitics from "../pages/geopolitics";
import NotFound from "../pages/notfound/NotFound";
import ConfirmDialog from "../pages/authorization/registration/сonfirm";
import SearchPage from "../pages/search";
import Economy from "../pages/economy";
import Logistics from "../pages/logistics";
import Opinions from "../pages/opinions";


export interface IRoute {
    path: string;
    element: React.ComponentType;
}

export const publicRoutes: IRoute[] = [
    {path: RouteNames.REGISTRATION, element: Registration}
]

export const privateRoutes: IRoute[] = [
    {path: RouteNames.REGISTRATION, element: Registration},
    {path: RouteNames.CONFIRM, element: ConfirmDialog},
    {path: RouteNames.DETAIL, element: Detail},
    {path: RouteNames.POLITICS, element: Politics},
    {path: RouteNames.ECONOMY, element: Economy},
    {path: RouteNames.LOGISTICS, element: Logistics},
    {path: RouteNames.OPINIONS, element: Opinions},
    {path: RouteNames.GEOPOLITICS, element: GeoPolitics},
    {path: RouteNames.SEARCH, element: SearchPage},
    {path: RouteNames.NOTFOUND, element: NotFound},
    {path: RouteNames.MAIN, element: Main},
]