import useMediaQuery from 'use-media-antd-query';

export const useIsXSmall = () => {
    const colSize = useMediaQuery();
    return !(colSize === 'xs' || colSize === 'sm' );
}
export const useIsTable = () => {
    const colSize = useMediaQuery();
    return !(colSize === 'md');
}
