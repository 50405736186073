import React, {useEffect, useState} from 'react';
import Layouts from "../../layout";
import {Col, Flex, Row, Spin} from "antd";
import {useIsXSmall} from "../../hooks/useIsXSmall";
import {NavLink} from "react-router-dom";
import {RouteNames} from "../../router/names";
import back_left from "../../assets/image/back_left.png";
import SmailBlockLeft from "./SmailBlockLeft";
import BigBlock from "./BigBlock";
import {RootState, useStoreDispatch} from "../../redux";
import {setDataGeoPoliticsReducers,geoPoliticsD} from "../../redux/geoPoliticsData";
import { LoadingOutlined } from '@ant-design/icons';
import GeoSkeleton from "../../components/skeleton/GeoSkeleton";
import {useSelector} from "react-redux";

const GeoPolitics = () => {
    const isXSmall = useIsXSmall();
    const dispatch = useStoreDispatch();
    const dataGeoPoliticsxx: any = useSelector((state: RootState) => state.geoPoliticsData.data);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [fetching, setFetching] = useState(true);
    const [total, setTotal] = useState<number>(0);
    const [dataGeoPolitics, setDataGeoPolitics]: any[] = useState<any[]>([]);
    const [dataGeoPoliticsOrigin, setDataGeoPoliticsOrigin]: any[] = useState<any[]>([]);
    const gutters: Record<PropertyKey, number> = {};
    const vgutters: Record<PropertyKey, number> = {};
    const cols: any[] = [];
    let counter = 1;

    useEffect(() => {
        if (fetching){
            setLoading(true)
            dispatch(geoPoliticsD(currentPage)).then(response =>{
                setDataGeoPolitics([...dataGeoPolitics, ...response.payload])
                setCurrentPage(prevState => prevState + response.payload.len)
                setDataGeoPoliticsOrigin(response.payload.data)
                setTotal(response.payload.total)
                dispatch(setDataGeoPoliticsReducers([...dataGeoPolitics, ...response.payload]))
                setLoading(false)
            }).finally(() => setFetching(false))
        }
        document.addEventListener('scroll',scrollHandler);
        return function () {
            document.removeEventListener("scroll", scrollHandler)
        }

    }, [fetching]);


    for (let i = 0; i < dataGeoPolitics.length; i++) {
        if (i % 3 == 0) {
            cols.push(
                <Col key={i.toString()} span={24}>
                    <BigBlock item={dataGeoPolitics[i]} data={dataGeoPolitics} i={i} IsXSmall={isXSmall} counter={counter}/>
                </Col>
            );
        } else {
            if (counter > 2) {counter = 1}
            cols.push(
                <Col key={i.toString()} span={isXSmall ? 24 /2 : 24}>
                    <SmailBlockLeft item={dataGeoPolitics[i]} data={dataGeoPolitics} i={i} IsXSmall={isXSmall}
                                    counter={counter}/>
                </Col>
            );
            counter++
        }
    }

    const scrollHandler = (e: any) => {
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) - 1000 < 100 &&
            dataGeoPoliticsxx.length < total) {
            setFetching(true)
        }
    }

    return (
        <Layouts>
            <div style={isXSmall ? {width: '100%', height: 40} : {width: '100%', height: 0}}/>
            <Flex vertical={!isXSmall}>
                <Col xs={24} sm={3} md={3} lg={3} xl={3} style={{height: 116}}>
                    <Flex justify={"flex-start"} align={"flex-end"} style={{position: "relative",bottom: 7}}>
                        <NavLink to={RouteNames.MAIN}>
                            <img src={back_left} alt={"back_left"}/>
                        </NavLink>
                    </Flex>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                    <Flex vertical>
                        <div className={"text44Tinos"}>Геополитика</div>
                        {isXSmall &&
                        <div style={{width: '100%', height: 21}}/>
                        }
                        <Col style={{marginBottom: 40}}>
                            {dataGeoPolitics.length !== 0 ?
                                <Row gutter={[gutters[8], vgutters[8]]}>
                                    {cols}
                                </Row>
                                :
                                <GeoSkeleton col={5}/>
                            }
                        {/*    <Row gutter={[8, 8]}>
                            {dataGeoPoliticsxx.length !== 0 ?
                                dataGeoPoliticsxx.map((item: any, i: number) =>
                                  <div key={i}>
                                          <Col span={12} style={{ width: '50%',height: 50,background: 'blue'}}/>
                                    <Row>
                                        {item.small1 !== undefined &&
                                        <SmailBlockLeft item={item.small1} data={dataGeoPoliticsOrigin} i={i} IsXSmall={isXSmall}/>
                                        }
                                        {item.small2 !== undefined &&
                                        <SmailBlockRight item={item.small2} data={dataGeoPoliticsOrigin} i={i} IsXSmall={isXSmall}/>
                                        }
                                    </Row>
                                    {item.big !== undefined &&
                                      <BigBlock item={item.big} data={dataGeoPoliticsOrigin} i={i} IsXSmall={isXSmall}/>
                                    }
                                  </div>

                                ) : (
                                  <GeoSkeleton col={5}/>
                                )
                            }
                            </Row>*/}
                        </Col>
                    </Flex>
                    <Flex style={{width: '100%',marginBottom: 40}} justify={'center'}>
                        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </Flex>
                </Col>
            </Flex>
        </Layouts>
    );
};

export default GeoPolitics;