import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchColViews, fetchNews} from "../api/news";
import {GetNewsDataType} from "../api/news/types/news_type";

interface IUsersState {
    data: Array<GetNewsDataType>,
}

const initialState: IUsersState = {
    data: [],
};
export const newsD = createAsyncThunk(
    'news',
    async () => {
        const response: any = await fetchNews();
        response.data.data.dataCenter.map((el: any) =>{
            el.pay = true
        })
        return response.data.data
    }
)
export const setClickViews = createAsyncThunk(
    'news',
    async (postId: {}) => {
        const response: any = await fetchColViews(postId);
        return null
    }
)

const newsSlice = createSlice({
    name: 'drawer',
    initialState,
    reducers: {
        setHiddenPay: (state, action) => {
            state.data = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(newsD.fulfilled, (state, action) => {
            state.data = action.payload
        })
    },
});
export default newsSlice.reducer;
export const { setHiddenPay } = newsSlice.actions;