import axios from 'axios';
import axiosRetry from 'axios-retry';
import {AxiosInstance, InternalAxiosRequestConfig, AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios';
const whiteRetry = new Set(['ECONNABORTED', undefined, 0]);

const serviceAxios = axios.create({
    baseURL: '',
    timeout: 15 * 1000,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    },
    validateStatus() {
        return true;
    }
});

axiosRetry(serviceAxios, {
    retries: 2,
    shouldResetTimeout: true,
    retryDelay: (retryCount) => {
        return retryCount * 10000;
    },
    retryCondition: (err) => {
        const {code, message} = err;
        return whiteRetry.has(<string>code) || message.includes('timeout');
    }
});

serviceAxios.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        return config;
    },
    (err: AxiosError) => {
        return Promise.reject(err);
    }
);

serviceAxios.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (err: AxiosError) => {
        return Promise.reject(err);
    }
);

function createRequest(service: AxiosInstance) {
    return function <T>(config: AxiosRequestConfig): Promise<T> {
        return service(config);
    };
}

export default createRequest(serviceAxios);