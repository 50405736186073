import {createSlice} from "@reduxjs/toolkit";

interface IUsersState {
    drawer: false,
    sound: false,
}

const initialState: IUsersState = {
    drawer: false,
    sound: false,
};

const drawerSlice = createSlice({
    name: 'drawer',
    initialState,
    reducers: {
        setDrawer: (state, action) => {
            state.drawer = action.payload
        },
        setSound: (state, action) =>{
            state.sound = action.payload
        }
    },
});
export default drawerSlice.reducer;
export const { setDrawer, setSound } = drawerSlice.actions;