import React, {useEffect, useState} from 'react';
import {Col, Divider, notification, Row, Space} from "antd";
import {StarFilled} from "@ant-design/icons/lib";
import logo from "../../assets/image/logo.png"
import user from "../../assets/image/user.png"
import search from "../../assets/image/search.png"
import sound from "../../assets/image/sound.png"
import sound_no from "../../assets/image/sound_no.png"
import divider from "../../assets/image/divider.png"
import drawerImg from "../../assets/image/drawer.png"
import Menu from "../menu";
import {useIsTable, useIsXSmall} from "../../hooks/useIsXSmall";
import {NavLink} from "react-router-dom";
import {RouteNames} from "../../router/names";
import Registration from "../../pages/authorization/registration";
import "./style.scss"
import ConfirmDialog from "../../pages/authorization/registration/сonfirm";
import { useNavigate } from "react-router-dom";
import Donation from "../../pages/donation";
import {useSelector} from "react-redux";
import {RootState, useStoreDispatch} from "../../redux";
import { setDrawer } from "../../redux/drawer";
import Drawers from "../drawer";
import { setSound } from "../../redux/drawer"
import {NotificationPlacement} from "antd/es/notification/interface";

const Header = () => {
    const navigate = useNavigate();
    const isXSmall = useIsXSmall();
    const isTable = useIsTable();
    const dispatch = useStoreDispatch()
    const drawer: boolean = useSelector((state: RootState) => state.drawer.drawer);
    const isSound: boolean = useSelector((state: RootState) => state.drawer.sound);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenConfirm, setIsModalOpenConfirm] = useState(false);
    const [isModalOpenDonation, setIsModalOpenDonation] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    notification.config({bottom: 50, duration: 3, rtl: false})

    const showModalDonation = () => {
        setIsModalOpenDonation(true);
    };
    const handleCancelDonation = () => {
        setIsModalOpenDonation(false);
    };
    const showModal = () => {
        isXSmall ? setIsModalOpen(true) : navigate(RouteNames.REGISTRATION);
        setIsModalOpen(true)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleCancelConfirm = () => {
        setIsModalOpenConfirm(false);
    };
    const login = (val: any) => {
        setIsModalOpen(false);
        setIsModalOpenConfirm(true)
    };
    const onOpenDrawer = (val: any) => {
      drawer ? dispatch(setDrawer(false)) : dispatch(setDrawer(true))
    }
    const onCloseDrawer = () => {
        dispatch(setDrawer(false))
    };

    useEffect(() => {
        const sound = localStorage.getItem('sound');
        if (sound == String(true)){
            dispatch(setSound(true))
        }else {
            dispatch(setSound(false))
        }
    }, []);

    const confMessage = (text: string,placement: NotificationPlacement) => {
        return api.open({message: text, placement,
            style: {
                background: "black",
                color: "white",
                width: 195,
                padding: '11px 11px',
                fontSize: 16,
                fontFamily: 'Inter, serif',
                borderRadius: 4
        }});
    }

    const handleSound = (placement: NotificationPlacement) =>{
       const sound = localStorage.getItem('sound');
       if (sound == String(true)){
           localStorage.setItem('sound', String(false));
           dispatch(setSound(false))
           confMessage('Звук выключен',placement)
       }else {
           localStorage.setItem('sound', String(true));
           dispatch(setSound(true))
           confMessage('Звук включен',placement)
       }

    }

    return (
        <div className={"header"}>
            {contextHolder}
            <Row className={isXSmall ? "row" : "mobile-row" } >
                {isXSmall && isTable ?
                    <Col span={8}>
                        <Space direction="vertical" style={{display: 'flex'}}>
                            <Row align="middle">
                                <div className={"box-left"}>
                                    <StarFilled className={"star"}/>
                                    <div className={"pull-up"} onClick={showModalDonation}>Поддерджать проект</div>
                                </div>
                                <div className={"box-divider"}>
                                    <Divider className={"divider"}/>
                                </div>
                            </Row>
                        </Space>
                    </Col>
                    :
                    <Col span={isXSmall ? 8 : 4} className={isXSmall ? "drw-icon" : "drw-icon-mobile"} onClick={onOpenDrawer}>
                        <img src={drawerImg} alt={'drawer'} className={"icon"}/>
                    </Col>
                }
                <Col span={isXSmall ? 8 : 15} style={{textAlign: 'center'}}>
                    <NavLink to={RouteNames.MAIN}>
                        <img src={logo} className={isXSmall ? 'logo' : "logo-mobile"} alt={"logo"}/>
                    </NavLink>
                </Col>
                {isXSmall ?
                    <Col span={8} style={{paddingLeft: 22,alignSelf: "center"}}>
                        <Space direction="vertical" style={{display: 'flex'}}>
                            <Row align="middle">
                                <div className={"box-right"}>
                                    <img src={user} alt={''} className={"icon"} onClick={showModal} width={24} height={24}/>
                                    <NavLink to={RouteNames.SEARCH}>
                                        <img src={search} alt={''} className={"icon"} width={24} height={24}/>
                                    </NavLink>
                                    <div className={"icon"} onClick={() => handleSound('bottom')}>
                                        <img src={isSound ? sound : sound_no} alt={''} width={26} height={26}/>
                                    </div>
                                </div>
                                {isTable &&
                                    <div className={"box-divider-right"}>
                                        <Divider className={"divider"}/>
                                    </div>
                                }

                            </Row>
                        </Space>
                    </Col>
                    :
                    <Col span={5} className={isXSmall ? "drw-icon" : "drw-icon-mobile"}>
                        <NavLink to={RouteNames.SEARCH}>
                            <img src={search} alt={''} className={"icon"} width={24} height={24}/>
                        </NavLink>
                        <img src={user} alt={''} className={"icon"} onClick={showModal}/>
                    </Col>
                }
                {isXSmall &&
                <>
                    <Col span={24}>
                        <img src={divider} alt={''} className={"img-divider"}/>
                    </Col>
                    <Col span={24}>
                        <Menu data={"header"}/>
                    </Col>
                </>
                }

            </Row>
            <Col span={24} style={{justifyContent: 'center', display: 'flex'}}>
                <div className={isXSmall ? "box-divider-bottom" : 'box-divider-bottom-mobile'}>
                    <div className={"divider-bottom"}/>
                    <div className={"divider-bottom"}/>
                </div>
            </Col>
            {
                isXSmall &&
                <Registration
                    isModalOpen={isModalOpen}
                    handleCancel={handleCancel}
                    login={login}
                    isXSmall={isXSmall}
                />
            }
            {
                isXSmall &&
                <ConfirmDialog
                    isModalOpenConfirm={isModalOpenConfirm}
                    handleCancelConfirm={handleCancelConfirm}
                    isXSmall={isXSmall}
                />
            }
            <Donation isModalOpenDonation={isModalOpenDonation} handleCancelDonation={handleCancelDonation}/>
            <Drawers drawer={drawer} onCloseDrawer={onCloseDrawer}/>
        </div>
    );
};

export default Header;